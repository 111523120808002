import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import { Context as ArtContext } from '../../context/ArtContext';
import matte2 from '../../assets/Rectangle 27.png'
import PaperF from '../../assets/Home/Paper-frame.png'
import CanvasF from '../../assets/Home/Canvas-frame.png'
import Paper from '../../assets/Home/Paper.jpg'
import Canvas from '../../assets/Home/Canvas.jpg'
import Footer from '../../components/Footer/footer';
import Header from '../../components/header/header';
import Price from '../../helpers/price';
import { base_url } from '../../helpers/baseURL';
import Loading from "react-js-loader";
import arrow from "../../assets/arrow-left.png"
import FrameEditPreview from '../../components/frame/frameEditPreview';
import Popup from 'reactjs-popup';
import { MetaTags } from 'react-meta-tags';



const ShopArt = () => {
    const { state, setRatio, getSingleArt, getSingleFrame, calculatePrice, calculateDPI, image_upload, setCropImg, setPadding, cartTrig } = useContext(ArtContext);
    const [selected, setselected] = useState(2)
    const [paper, setpaper] = useState("")
    const [pin, changepin] = useState('')
    const [loader, setloader] = useState(false)
    const [artdatas, setartdatas] = useState('')
    const [artInfo, setartInfo] = useState('')
    const [continuestatus, setcontinue] = useState(false)
    const [items, setitem] = useState(false)
    const [add, openAdd] = useState(false)
    const [artist, setArtist] = useState('')
    const [value, setValue] = useState(0)
    const [zoom, openZoom] = useState(false)

    const [pinstatus, setpinstatus] = useState(false)
    let params = useParams();
    const history = useHistory();
    const alert = useAlert()
    function get_url_extension(url) {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }
    const calculate = (item) => {
        let rat = item.split("x")
        console.log("ratt", rat)
        let rat2 = Number(rat[0].replace(' ', '')) / Number(rat[1].replace(' ', ''))
        console.log("ratt", rat2)
        let payload = { ratio: rat2, text: item, width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }
        console.log("ratt2", payload)
        console.log('size selected', item)
        let sqi = payload.width * payload.height
        let framesqi = (state.ratio.width + 3) * (state.ratio.height + 3)
        localStorage.getItem("artmode") === "frame" ? calculatePrice(framesqi, 'frame') : calculatePrice(sqi)
    }
    var limit1, limit2 = 0, limit3, sam1 = [], sam2 = [], sam3 = [], sam4 = [];

    var sam = [].concat(state.printsizes);



    sam.map((item, ind) => {
        if (item) {
            var rat = item.split("x")
            console.log(rat)
            let payload = { width: Math.round(Number(rat[0].replace(' ', ''))), height: Number(rat[1].replace(' ', '')) }
            if (payload.width >= payload.height) {
                if (payload.width < 14) {
                    // limit1 = ind;
                    sam1.push(item)
                }
                else if ((payload.width >= 14) && (payload.width < 24)) {
                    // limit2 = ind;
                    // console.log("awr" + ind + " " + payload.width)
                    sam2.push(item)
                }
                else if ((payload.width >= 24) && (payload.width < 36)) {
                    // limit3 = ind;
                    sam3.push(item)
                }
                else
                    sam4.push(item)
            }
            else {
                if (payload.height < 14) {
                    // limit1 = ind;
                    sam1.push(item)
                }
                else if ((payload.height >= 14) && (payload.height < 24)) {
                    // limit2 = ind;
                    // console.log("awr" + ind + " " + payload.height)
                    sam2.push(item)
                }
                else if ((payload.height >= 24) && (payload.height < 36)) {
                    // limit3 = ind;
                    sam3.push(item)
                }
                else
                    sam4.push(item)
            }
            console.log("ratt2", payload.width)
        }
    })
    sam1 = sam1.reverse();
    sam2 = sam2.reverse();
    sam3 = sam3.reverse();
    sam4 = sam4.reverse();
    useEffect(() => {
        console.log("selected type", paper)

        window.scrollTo(0, 0)
        if (params.artid) {
            getSingleArt(params.artid)
                .then(res => {
                    var image = new Image();
                    console.log('response of art', res)
                    setartdatas(res.artData)
                    setCropImg(res.url)
                    localStorage.setItem('frameImage', res.url)
                    //Set the Base64 string return from FileReader as source.
                    image.src = res.url;

                    //Validate the File Height and Width.
                    image.onload = function () {
                        var height = this.naturalHeight;
                        var width = this.naturalWidth;
                        localStorage.setItem("ratio", width / height)
                        calculateDPI(res.height, res.width, "art", history, 'art', paper, height, width)
                    };
                    console.log("art details", state.artData)
                })
                .catch(err => {
                    history.push('/404s')
                })
        }

    }, [paper])


    useEffect(() => {
        if (localStorage.getItem("pincode")) {
            changepin(localStorage.getItem("pincode") ? localStorage.getItem("pincode") : "")
            checkpin(localStorage.getItem("pincode"))
        }
        localStorage.setItem("papertype", "Paper")
        getSingleFrame()
        console.log("selected type", paper)
        window.scrollTo(0, 0)
        if (params.artid) {
            getSingleArt(params.artid, "Paper")
                .then(res => {
                    var image = new Image();
                    console.log('response of art', res)
                    setartdatas(res.artData)
                    let artist = res.artist.split(",").reverse()
                    console.log("reversed artist", artist)
                    setArtist(`${artist.toString()}`)
                    setCropImg(res.url)
                    localStorage.setItem('frameImage', res.url)
                    //Set the Base64 string return from FileReader as source.
                    image.src = res.url;

                    //Validate the File Height and Width.
                    image.onload = function () {
                        var height = this.naturalHeight;
                        var width = this.naturalWidth;
                        const final = Object.assign(res, { "rheight": height, "rwidth": width })
                        setartInfo(final)
                        console.log("final art info", final)
                        localStorage.setItem("ratio", width / height)
                        localStorage.setItem("art_height", res.height)
                        localStorage.setItem("art_width", res.width)
                        calculateDPI(res.height, res.width, "art", history, 'art', paper, height, width)
                    };
                    console.log("art details", state.artData)
                })
                .catch(err => {
                    history.push('/404s')
                })
        }

    }, [])


    useEffect(() => {
        if (paper && artInfo) {
            calculateDPI(artInfo.height, artInfo.width, "art", history, "art", paper, artInfo.rheight, artInfo.rwidth)
        }
        let divs = (document.body.scrollWidth / 12) * 2.5
        console.log("framed container width", (document.body.scrollWidth / 12) * 2.5)
        if (selected && continuestatus)
            calculate(selected)
        if (localStorage.getItem('ratio') && window.innerWidth > 575) {
            if (localStorage.getItem('ratio') < 0.40)
                paper == "Canvas" ? setValue(120) : setValue(100)
            else if (localStorage.getItem('ratio') >= 0.40 && localStorage.getItem('ratio') < 0.52)
                paper == "Canvas" ? setValue(divs * 0.56) : setValue(divs * 0.48)
            else if (localStorage.getItem('ratio') >= 0.52 && localStorage.getItem('ratio') < 0.72)
                paper == "Canvas" ? setValue(divs * 0.7) : setValue(divs * 0.56)
            else if (localStorage.getItem('ratio') >= 0.72 && localStorage.getItem('ratio') < 1)
                paper == "Canvas" ? setValue(divs * 0.8) : setValue(divs * 0.75)
            else if (localStorage.getItem('ratio') >= 1 && localStorage.getItem('ratio') < 1.4)
                paper == "Canvas" ? setValue(divs) : setValue(divs * 0.82)
            else if (localStorage.getItem('ratio') >= 1.4)
                paper == "Canvas" ? setValue(divs) : setValue(divs)
        }
        else if (localStorage.getItem('ratio') && window.innerWidth < 575) {
            if (localStorage.getItem('ratio') < 0.52)
                setValue(100)
            else if (localStorage.getItem('ratio') >= 0.52 && localStorage.getItem('ratio') < 0.72)
                setValue(120)
            else if (localStorage.getItem('ratio') >= 0.72 && localStorage.getItem('ratio') < 1)
                setValue(160)
            else if (localStorage.getItem('ratio') >= 1 && localStorage.getItem('ratio') < 1.4)
                setValue(160)
            else if (localStorage.getItem('ratio') >= 1.4)
                setValue(160)
        }
    }, [paper, localStorage.getItem('ratio')], document.body.scrollWidth)
    const checkpin = (code) => {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (code === '' || re.test(code)) {
            changepin(code)
        }
        if (code.length === 6) {
            axios({
                method: 'get',
                url: base_url + '/jsonapi/taxonomy_term/pincode?filter[productFilter][condition][path]=name&filter[productFilter][condition][value]=' + code
            })
                .then(res => {
                    if (res.data.data.length) {
                        setpinstatus(true)
                        localStorage.setItem("pincode", code)
                    }
                    else {
                        setpinstatus(false)
                    }
                })
                .catch(err => {
                    console.log(err.code)
                })
        }
        else {
            setpinstatus(false)
        }
    }
    const checkAdd = () => {
        if (!pinstatus) {
            if (pin.length == 0) {
                alert.show("Please enter a pincode to check delivery availability")
            }
            else if (pin.length < 6)
                alert.show("Invalid Pin")
            else {
                alert.show("Sorry, the pincode is not serviceable")
            }
        }
        else if (!continuestatus)
            alert.show("Please choose a size")
        else
            setDataPrint()
    }
    const setDataPrint = async (msg) => {
        if (state.price) {
            setData()
            let artData = {
                "framesize": `${Number(state.ratio.width).toFixed(2)} X ${Number(state.ratio.height).toFixed(2)}`,
                "papertype": paper
            }
            setloader(true)
            let custom_data = localStorage.getItem('upload_image_var') ? JSON.parse(localStorage.getItem('upload_image_var')) : null
            artData.user_upload = false
            artData.cropped_image = null
            artData.og_image = null
            artData.field_print_or_frame = "print"
            artData.art_id = params.artid ? params.artid : null
            artData.item_id = params.artid ? params.artid : null
            artData.item_name = paper ? `Print on ${paper} - ${artdatas ? artdatas.data.attributes.title : "User Custom Image"}` : ""
            artData.art_varid = state.printVarData ? state.printVarData.id : null
            artData.height = state.ratio.height
            artData.width = state.ratio.width
            artData.art_title = artdatas ? artdatas.data.attributes.title : "User Custom Image"
            artData.art_price = state.price ? state.price : Number(custom_data[0].attributes.price.number)
            artData.total_price = state.price ? state.price : ""
            artData.dimension = (Number(state.ratio.width)).toFixed(2) + `"` + " X " + (Number(state.ratio.height)).toFixed(2) + `"`
            artData.varid = null
            console.log(artData)
            let cartData = localStorage.getItem("cartData") ? JSON.parse(localStorage.getItem("cartData")) : []
            if (cartData.length == 29) {
                alert.show("Maximum Cart Limit is 30")
                console.log("maximum achieved")
            }
            if (cartData.length < 30) {
                cartData.push(artData)
                localStorage.setItem("cartData", JSON.stringify(cartData));
                let checkoutdata = JSON.parse(localStorage.getItem("checkout_data"))
                if (checkoutdata) {
                    console.log("cart length", cartData.length)
                    checkoutdata.total_no = checkoutdata.total_no ? cartData.length !== 0 ? cartData.length : 1 : 1
                    checkoutdata.total_price = checkoutdata.total_price ? checkoutdata.total_price : 0
                    checkoutdata.total_price = checkoutdata.total_price + artData.total_price
                    localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                    cartTrig(state.cartTrigger + 1)
                }

                else {
                    let checkoutdata = {
                        "total_no": 1,
                        "total_price": artData.total_price
                    }
                    localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                    cartTrig(state.cartTrigger + 1)
                }
                setloader(false)
                msg ? history.push("/checkout/details") :
                    openAdd(true)
            }
            else
                alert.show("Maximum Cart limit reached")
        }

    }
    const setData = () => {
        let sqi = state.ratio.width * state.ratio.height
        let framesqi = (state.ratio.width + 3) * (state.ratio.height + 3)
        localStorage.getItem("artmode") === "frame" ? calculatePrice(framesqi, 'frame') : calculatePrice(sqi)
        console.log(state.printVarData)
        let artData = {
            "framesize": `${state.ratio.width} x ${state.ratio.height}`,
            "papertype": paper
        }
        let og_image_name = state.printVarData ? '.' + get_url_extension(state.artData.img) : '';
        if (og_image_name)
            localStorage.setItem('og_image_name', og_image_name)

        let custom_data = localStorage.getItem('upload_image_var') ? JSON.parse(localStorage.getItem('upload_image_var')) : null
        artData.user_upload = localStorage.getItem('user_upload') ? true : false
        artData.field_print_or_frame = "print"
        artData.art_id = params.artid ? params.artid : null
        artData.art_varid = state.printVarData ? state.printVarData.id : null
        artData.height = state.ratio.height
        artData.width = state.ratio.width
        artData.artist = artist ? artist : ""
        artData.art_title = artData.art_title = artdatas ? artdatas.data.attributes.title : "User Custom Image"

        artData.art_title = artData.art_title = artdatas ? artdatas.data.attributes.title : "User Custom Image"
        artData.art_price = state.price ? state.price : ""
        artData.total_price = state.price ? state.price : ""
        console.log(artData)
        // artData.cropped_image = state.cropImg
        // artData.og_image = state.printVarData ? state.upImg : null

        localStorage.setItem("artData", JSON.stringify(artData));

        console.log(artData.artist + "  anwar")
    }
    return (
        <div className="shop_art">
            <Header />
            {artdatas.data ? artdatas.data.attributes.metatag_normalized ? artdatas.data.attributes.metatag_normalized.length ?
                <MetaTags>
                    {artdatas.data.attributes.metatag_normalized.map((item) => {
                        if (item.tag === "meta")
                            if (item.attributes.name === "title")
                                return ""
                            else
                                return <meta name={`${item.attributes.name}`} content={`${item.attributes.content}`} />
                    })}
                    <meta property="og:type" content="og:product" />
                    <meta property="og:title" content={artdatas.data ? artdatas.data.attributes.title : ""} />
                    <meta property="og:image" content={localStorage.getItem('showimage')} />
                    <meta property="og:url" content={`/shop_art/${params.artid}`} />
                    <meta property="og:site_name" content="Espravo" />
                    <meta name="twitter:card" content="" />
                    <meta name="twitter:site" content="@twitterid" />
                    <meta name="twitter:title" content={artdatas.data ? artdatas.data.attributes.title : ""} />
                    <meta name="twitter:image" content={localStorage.getItem('showimage')} />

                </MetaTags>
                : "" : '' : ""
            }
            <div className='header-container'>
                <Link className="mb-1 selectBack brandon d-flex align-items-center mt-3" to={`/shop_art_type/${params.artid}`} onClick={() => { localStorage.setItem("artmode", "shopart") }}>
                    <img src="/assets/arrow-left.png" className="selectBackIcon" alt="thumb"></img>
                    <p className='ml-2'>Back</p>
                </Link>
            </div>
            {artdatas ? <div className="container d-flex flex-wrap mt-5 margin-bottom">
                {/* <Link className="see-all-frames d-flex container" to="/art/" onClick={() => { localStorage.setItem("artmode", "shopart") }}>
                    <img src="/assets/arrow-left.png" alt="thumb" />
                    <p>See All Art</p>
                </Link> */}
                <div className="frame_prev_mob d-none mob-only my-4">
                    <Carousel controls={false}>
                        <Carousel.Item interval={100000}>
                            <div className='frame-edit-preview-container'>
                                <img src="/assets/images/backimg.jpeg" alt="thumb" className="stat-img" />
                                <div className='frame-edit-preview-img'> </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item interval={100000}>
                            <div className="">
                                <div className="App">
                                    <div id="frame-container">
                                        <div className="">
                                            <img src={localStorage.getItem('showimage')} className='show-img' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div id="frame_preview" className="col-12 pl-sm-0 col-sm-5 h-auto ">
                    <div className='d-none'>{state.printVarData ? state.printVarData.id : "Not right"}</div>
                    <div className={localStorage.getItem("artmode") == "frame" ? "main" : "no-frame"}>
                        <div className='frame-edit-preview-container'>
                            {localStorage.getItem("artmode") == "frame" ? value ? <FrameEditPreview value={value} place={"shopart"} props={{ "padding": paper == "Canvas" ? 1.3 : 4.5 }} framing={{ "depth": 32, "frame": paper == "Canvas" ? 1.3 : 3 }} /> : "" :
                                <div className='p-4'>
                                    <img src={localStorage.getItem('showimage')} className='frame-edit-preview show-img' />
                                </div>
                            }
                            {localStorage.getItem("artmode") == "frame" ? "" : <div className="zoom-button-container">
                                <img src="/assets/zoom.png" className="zoom-button" onClick={() => openZoom(true)} />
                            </div>
                            }
                        </div>

                    </div>
                    {/* <div className="d-flex thumb-cont">
                        <div onClick={() => setselected(1)} className={selected === 1 ? "col-sm-3 thumb active" : "col-sm-3 thumb"}>
                            <img src="/assets/images/thumb1.png" alt="thumb" />
                        </div>
                        <div onClick={() => setselected(2)} className={selected === 2 ? "col-sm-3 thumb active" : "col-sm-3 thumb"}>
                            <img src="/assets/images/thumb2.png" alt="thumb" />
                        </div>
                        {state.thumbnail ? <div onClick={() => setselected('/assets/images/thumb3.png')} className={selected === "/assets/images/thumb3.png" ? "col-sm-3 thumb active" : "col-sm-3 thumb"}>
                            <img src="/assets/images/thumb3.png" alt="thumb" />
                        </div> : ""}
                        {state.frameVideo ? <div onClick={() => setselected(4)} className={selected === 4 ? "col-sm-3 thumb active" : "col-sm-3 thumb"}>
                            <img src="/assets/images/video.jpg" className='vid-img' alt="thumb" />
                        </div> : ''}
                    </div> */}
                </div>
                <div className="col-sm-7 col-12 mb-5">
                    <div className="main-heading GT mt-3 mb-3 text-center secondarycolor">
                        {artdatas.data ? artdatas.data.attributes ? artdatas.data.attributes.title ? artdatas.data.attributes.title : "" : '' : ""}
                    </div>
                    <p className='brandon text-center'>
                        {artist ? artist.replace(",", " ") : ""}
                    </p>
                    <div className="choose-head brandon text-center mt-3 mb-3">
                        Choose a media
                    </div>
                    <div className=''>
                        <div className="paper-cont brandon row">
                            <div className="paper-type order-2 col-6 pr-0" onClick={() => { setpaper("Canvas"); localStorage.setItem("papertype", "Canvas"); setcontinue(false) }}>
                                {localStorage.getItem("artmode") == "frame" ? <img style={{ border: paper === "Canvas" ? '4px solid brown' : '' }} alt="icon" src={CanvasF} /> : <img style={{ border: paper === "Canvas" ? '4px solid brown' : '' }} alt="icon" src={Canvas} />}
                                <p>Canvas</p>
                            </div>
                            <div onClick={() => { setpaper("Paper"); localStorage.setItem("papertype", "Paper"); setcontinue(false) }} className="paper-type col-6 pr-0">
                                {localStorage.getItem("artmode") == "frame" ? <img style={{ border: paper === "Paper" ? '4px solid brown' : '' }} alt="icon" src={PaperF} /> : <img style={{ border: paper === "Paper" ? '4px solid brown' : '' }} alt="icon" src={Paper} />}
                                <p>Paper</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="section-line" /> */}
                    {paper ? <div className="choose-head brandon text-center mt-5">
                        Select a size
                    </div> : ""}
                    {paper ? <p className="size_title brandon text-center">
                        Available Print Sizes [<strong>Inches</strong>]
                    </p> : ""}
                    {paper ?
                        state.printsizes ?

                            <div className="mb-sm-5 mb-3">
                                {
                                    sam1.length ?
                                        <div className='col-12 px-0 mx-auto'>
                                            <p className='brandon sizeTitle pt-sm-4 pt-0 pb-2 text-center'>Small</p>
                                            {
                                                sam1.length === 1 || sam1.length % 3 === 1 || sam1.length ?
                                                    <div className="justify-content-center w-100 odd px-0 mx-auto row">
                                                        {
                                                            sam1.map((item, ind) => {

                                                                var rat = item.split("x")
                                                                console.log(rat)

                                                                let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                return (
                                                                    <div style={{ marginRight: ind == sam1.length - 1 || (ind + 1) % 3 == 0 ? "0%" : "" }} onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab  selected" : "size-tab"}>
                                                                        {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                    </div>
                                                                )

                                                            })

                                                        }
                                                    </div> : <div className="col-lg-12 px-0 mx-auto row">
                                                        {
                                                            sam1.map((item, ind) => {

                                                                var rat = item.split("x")
                                                                console.log(rat)

                                                                let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                return (
                                                                    <div onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab  selected" : "size-tab"}>
                                                                        {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                    </div>
                                                                )

                                                            })

                                                        }
                                                    </div>
                                            }
                                        </div>

                                        : ""
                                }


                                {
                                    sam2.length ?
                                        <div className='col-12 px-0'>
                                            {console.log(sam2)}
                                            <p className='brandon sizeTitle pt-sm-4 pt-0 pb-2 text-center'>Medium</p>
                                            {
                                                sam2.length === 1 || sam2.length % 3 === 1 || sam2.length ?
                                                    <div className="justify-content-center w-100 odd px-0 mx-auto row">
                                                        {
                                                            sam2.map((item, ind) => {

                                                                var rat = item.split("x")
                                                                console.log(rat)

                                                                let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                return (
                                                                    <div style={{ marginRight: ind == sam2.length - 1 || (ind + 1) % 3 == 0 ? "0%" : "3%" }} onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab selected" : "size-tab"}>
                                                                        {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                    </div>
                                                                )
                                                            })

                                                        }
                                                    </div> : <div className="col-lg-12 px-0 mx-auto row">
                                                        {
                                                            sam2.map((item, ind) => {

                                                                var rat = item.split("x")
                                                                console.log(rat)

                                                                let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                return (
                                                                    <div onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab selected" : "size-tab"}>
                                                                        {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                    </div>
                                                                )
                                                            })

                                                        }
                                                    </div>
                                            }
                                        </div>
                                        : ""}

                                {
                                    sam3.length ?
                                        <div className='col-12 px-0'>
                                            <p className='brandon sizeTitle pt-sm-4 pt-0 pb-2  text-center'>Large</p>
                                            {
                                                sam3.length == 1 || sam3.length % 3 == 1 || sam3.length ?
                                                    (<div className="justify-content-center w-100 odd px-0 mx-auto row">
                                                        {
                                                            sam3.map((item, ind) => {

                                                                var rat = item.split("x")
                                                                console.log("availr" + limit2)
                                                                console.log("avail" + sam)
                                                                console.log("avail1" + sam2)

                                                                let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                return (
                                                                    <div style={{ marginRight: ind == sam3.length - 1 || (ind + 1) % 3 == 0 ? "0%" : "3%" }} onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab selected" : "size-tab"}>
                                                                        {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                    </div>
                                                                )
                                                            })

                                                        }
                                                    </div>)
                                                    :
                                                    (<div className="col-lg-12 px-0 mx-auto row">
                                                        {
                                                            sam3.map((item, ind) => {

                                                                var rat = item.split("x")

                                                                console.log("large" + sam3)

                                                                let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                return (
                                                                    <div onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab selected" : "size-tab"}>
                                                                        {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                    </div>
                                                                )
                                                            })

                                                        }
                                                    </div>)
                                            }
                                        </div>
                                        : ""

                                }
                                {
                                    sam4.length ?
                                        <div className='col-12 px-0'>
                                            {console.log(sam4)}
                                            <p className='brandon sizeTitle pt-sm-4 pt-0 pb-2 text-center'>Extra Large</p>
                                            {
                                                sam4.length === 1 || sam4.length % 3 === 1 || sam4.length ?
                                                    <div className="justify-content-center w-100 odd px-0 mx-auto row">
                                                        {
                                                            sam4.map((item, ind) => {

                                                                var rat = item.split("x")
                                                                console.log(rat)

                                                                let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                return (
                                                                    <div style={{ marginRight: ind == sam4.length - 1 || (ind + 1) % 3 == 0 ? "0%" : "3%" }} onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab selected" : "size-tab"}>
                                                                        {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                    </div>
                                                                )
                                                            })

                                                        }
                                                    </div> : <div className="col-lg-12 px-0 mx-auto row">
                                                        {
                                                            sam4.map((item, ind) => {

                                                                var rat = item.split("x")
                                                                console.log(rat)

                                                                let payload = { width: Number(rat[0].replace(' ', '')), height: Number(rat[1].replace(' ', '')) }

                                                                return (
                                                                    <div onClick={() => { setRatio(item); setcontinue(true); setselected(item); calculate(item) }} className={continuestatus && state.ratio.text === item ? "size-tab selected" : "size-tab"}>
                                                                        {(payload.width).toFixed(2) + "\tx\t" + (payload.height).toFixed(2)}
                                                                    </div>
                                                                )
                                                            })

                                                        }
                                                    </div>
                                            }
                                        </div>
                                        : ""}


                            </div>

                            : <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} />
                        : ""}
                    {/* {paper ? <div className="section-line" /> : ""} */}
                    <div className="container d-flex justify-content-center text-center">
                        {paper ? localStorage.getItem("artmode") == "frame" ?
                            <Link to={continuestatus ? `/frame/${params.artid}?type=art` : `/shop_art/${params.artid}`} className="choose-frame-button brandon text-uppercase mx-auto py-2 px-4 bgsecondary whitecolor" onClick={() => continuestatus ? setData() + localStorage.setItem("back", `/shop_art/${params.artid}`) : alert.show("Please choose a size")}>Choose A Frame</Link>
                            : "" : ''}
                    </div>
                    {paper && localStorage.getItem("artmode") == "art" ? <div className="input-pin mb-3">
                        <input className='brandon pincode-input' value={pin} onChange={(e) => checkpin(e.target.value)} placeholder="Enter Pincode For Delivery Availability" />
                        {
                            pin.length ? pin.length === 6 ?
                                <p className={pinstatus && pin.length === 6 ? "green brandon" : "red brandon"}>{pinstatus && pin.length === 6 ? "Delivery in 5-7 days" : "Delivery Unavailable"}</p>
                                : '' : ''
                        }
                    </div> : ""}
                    {paper ? localStorage.getItem("artmode") == "art" ? <div className="container text-center">
                        {/* <p className="frame-price headmedium">
                            {state.price && continuestatus ? <span>₹ <Price value={state.price} /></span> : ''}
                        </p> */}
                    </div> : "" : ''}
                    {continuestatus ? localStorage.getItem("artmode") == "art" ? <div className="print-check d-flex justify-content-space-between">
                        <p className="frame-price add-price brandon my-auto headmedium">
                            {state.price && continuestatus ? <span>Rs. <Price value={state.price} /></span> : ''}
                        </p>
                        <div className="d-flex justify-content-end button ml-auto pr-0">
                            <div onClick={() => checkAdd()} className="shop-art-button add-cart-button brandon text-uppercase mr-sm-2 bgsecondary whitecolor">
                                Add To Cart
                            </div>
                            {/* <button onClick={() => continuestatus && pinstatus ? setDataPrint("checkout") : alert.show("Please choose a size")} className="shop-art-button brandon text-uppercase mx-auto py-2 px-sm-4 px-0 bgsecondary whitecolor">
                                Checkout
                            </button> */}
                        </div>
                    </div> : "" : ''}
                </div>

            </div> : <div className='container' style={{ minHeight: "60vh" }}> <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} /></div>}
            {zoom ? <div className='overlay zoom-image-overlay'></div> : ""}
            <Popup className="zoompopup" open={zoom} onClose={() => openZoom(false)}>
                <div className="d-flex zoompopup-container flex-column ">
                    <img className="zoom-close cursor ml-auto mb-3 d-flex justify-content-end" role="button" src="/assets/contactclose.png" onClick={() => openZoom(false)} />
                    <div className='p-relative'>
                        <img src={localStorage.getItem('showimage')} className='zoom-img' />
                        {/* <img className='water-mark' src='/assets/watermark.png' /> */}
                    </div>

                </div>
            </Popup>
            <Popup open={add} onClose={() => openAdd(false)}>
                <div className="alert-popup">
                    <img className="zoom-close cursor ml-auto d-flex justify-content-end" role="button" src="/assets/contactclose.png" onClick={() => openAdd(false)} />
                    <div className="GT alert-heading">
                        Added to cart
                    </div>
                    <p className="brandon alert-desc">
                        Product successfully added to your shopping cart.
                    </p>
                    <div className="d-flex">
                        <div className="alert-continue cursor py-2 brandon" onClick={() => openAdd(false)}>Continue shopping</div>
                        <div className="alert-proceed py-2 cursor brandon" onClick={() => { history.push('/checkout/shopping-bag/'); }}>Proceed to checkout</div>
                    </div>
                </div>
            </Popup>
            <Footer />
        </div>
    )
}
export default ShopArt;