import React, { useEffect, useState, useContext } from "react"
import Footer from "../../components/Footer/footer"
import Header from "../../components/header/header"
import { Context as ArtContext } from '../../context/ArtContext';
import { Context as ProductContext } from '../../context/ProductContext';
import { base_url } from '../../helpers/baseURL';
import HTMLReactParser from "html-react-parser";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Price from '../../helpers/price';
import { useAlert } from 'react-alert';
import Loader from '../../components/checkout/loader';
import Loading from "react-js-loader";
import Popup from "reactjs-popup";
import { MetaTags } from 'react-meta-tags';
import Share from "../../components/share/share";
import Tools from "../../components/tools/tools";




const GalleryWall = () => {
    const alert = useAlert()
    const { id } = useParams();
    const { state, getGallery, getSingleGallery, cartTrig } = useContext(ArtContext);
    let PContext = useContext(ProductContext)
    const [pin, changepin] = useState('')
    const [policy, setpolicy] = useState('')
    const [care, setCare] = useState("")
    const [selected, setselected] = useState(1)
    const [pinstatus, setpinstatus] = useState(false)
    const [tab, settab] = useState("description")
    const [zoom, openZoom] = useState(false)
    const [add, openAdd] = useState(false)
    const [listed, setListed] = useState()
    const [loader, setloader] = useState(false)
    const [closeit, closeiet] = useState(false)
    const history = useHistory();


    const checkAdd = () => {
        if (!pinstatus) {
            let element = document.getElementById('pincode-input');
            element.classList.add('error');
            element.focus()
            // if (pin.length == 0) {
            //     alert.show("Please enter a pincode to check delivery availability")
            // }
            // else if (pin.length < 6)
            //     alert.show("Invalid Pin")
            // else {
            //     alert.show("Sorry, the pincode is not serviceable")
            // }
        }
        else
            setDataWall()
    }
    const checkpin = (code) => {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (code === '' || re.test(code)) {
            let element = document.getElementById("pincode-input")
            if (element)
                element.classList.remove("error")
            changepin(code)
        }
        if (code.length === 6) {
            axios({
                method: 'get',
                url: base_url + '/jsonapi/taxonomy_term/pincode?filter[productFilter][condition][path]=name&filter[productFilter][condition][value]=' + code
            })
                .then(res => {
                    if (res.data.data.length) {
                        setpinstatus(true)
                        localStorage.setItem("pincode", code)
                    }
                    else {
                        setpinstatus(false)
                    }
                })
                .catch(err => {
                    console.log(err.code)
                })
        }
        else {
            setpinstatus(false)
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        if (localStorage.getItem("pincode")) {
            changepin(localStorage.getItem("pincode") ? localStorage.getItem("pincode") : "")
            checkpin(localStorage.getItem("pincode"))
        }
        console.log("gallery id", id)
        if (id) {
            PContext.getPolicy()
                .then(resp => {
                    let data = resp?.data
                    console.log("response of Return in gallery wall", data)
                    setpolicy(data)
                })
                .catch(err => {
                    console.error("error in Return gallery wall", err)
                })
            PContext.getCare()
                .then(resp => {
                    let data = resp?.find(x => x.type === "gallery_care")?.data
                    console.log("response of Care in gallery wall", data)
                    setCare(data)
                })
                .catch(err => {
                    console.error("error in Care gallery wall", err)
                })
            getSingleGallery(id)
                .then(res => {
                    console.log("gallery found")
                    let variation_id = res.data[0]?.relationships.default_variation?.data.id
                    PContext.checkWish(variation_id)
                        .then(resp => {
                            setListed(resp)
                        })
                        .catch(err => {
                            console.error("error on check in wish", err)
                        })
                })
                .catch(err => {
                    console.log("err", err)
                    history.push("/404s")
                })
        }
    }, [id])
    const setDataWall = async () => {
        setloader(true)
        let galleryData = {
            "gallery_varid": state.singleGalleryVariation ? state.singleGalleryVariation.data[0].id : "",
            "title": state.singleGalleryWall.data.attributes.title,
            "item_name": state.singleGalleryWall.data.attributes.title,
            "frame": state.singleGalleryWall.data.attributes.field_frame,
            "dimension": state.singleGalleryWall.data.attributes.field_layout_size,
            "item_id": state.singleGalleryVariation ? state.singleGalleryVariation.data[0].id : "",
            "total_price": state.singleGalleryVariation.data[0].attributes.price ? Number(state.singleGalleryVariation.data[0].attributes.price.number) : "",
            "gallery_id": state.singleGalleryWall.data.id ? state.singleGalleryWall.data.id : "",
            "image_id": state.singleGalleryWall.data?.relationships.field_gallery_image?.data?.id,
            "quantity": 1
        }
        let checkoutdata = JSON.parse(localStorage.getItem("checkout_data"))
        let cartData = localStorage.getItem("cartData") ? JSON.parse(localStorage.getItem("cartData")) : []
        if (cartData) {
            let checkoutdata = localStorage.getItem("checkout_data") ? JSON.parse(localStorage.getItem("checkout_data")) : { total_no: 0 }
            if (Number(checkoutdata?.total_no) == 29) {
                alert.show("Maximum Cart Limit is 30")
                console.log("maximum achieved")
            }
            if (Number(checkoutdata.total_no) < 30) {
                console.log("index is", cartData.findIndex(x => x.gallery_varid == galleryData.gallery_varid))
                if (cartData.findIndex(x => x.gallery_varid == galleryData.gallery_varid) >= 0) {
                    let i = cartData.findIndex(x => x.gallery_varid == galleryData.gallery_varid)
                    if (cartData[i].quantity)
                        cartData[i].quantity = Number(cartData[i].quantity) + 1
                    else
                        cartData[i].quantity = 2
                }
                else {
                    cartData.push(galleryData)
                }
                localStorage.setItem("cartData", JSON.stringify(cartData));
                if (checkoutdata) {
                    checkoutdata.total_no = checkoutdata.total_no ? checkoutdata.total_no + 1 : 1
                    checkoutdata.total_price = checkoutdata.total_price ? checkoutdata.total_price : 0
                    checkoutdata.total_price = checkoutdata.total_price + galleryData.total_price
                    localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                    cartTrig(state.cartTrigger + 1)
                }

                else {
                    let checkoutdata = {
                        "total_no": 1,
                        "total_price": galleryData.total_price
                    }
                    localStorage.setItem("checkout_data", JSON.stringify(checkoutdata))
                    cartTrig(state.cartTrigger + 1)
                }
                setloader(false)
                openAdd(true)
                // alert.show(
                //     <div>
                //         <div className="GT alert-heading">
                //             Added to cart
                //         </div>
                //         <p className="brandon alert-desc">
                //             Product successfully added to your shopping cart.
                //         </p>
                //         <div className="d-flex">
                //             <div className="alert-continue brandon" onClick={() => alert.removeAll()}>Continue shopping</div>
                //             <div className="alert-proceed brandon" onClick={() => { alert.removeAll(); history.push('/checkout/details'); }}>Proceed to checkout</div>
                //         </div>
                //     </div>, {
                //     timeout: closeit ? 200 : 0
                // })
            }
            else
                alert.show("Maximum Cart limit reached")
        }
        setloader(false)
    }
    const addWish = () => {
        let id = state.singleGalleryVariation?.data[0].id
        setloader(true)
        PContext.addToWish(id)
            .then(res => {
                if (res === "item added") {
                    let variation_id = state.singleGalleryVariation?.data[0].id
                    PContext.checkWish(variation_id)
                        .then(resp => {
                            setListed(resp)
                            setloader(false)
                            alert.show("Added to Wishlist", { type: "success" });
                        })
                        .catch(err => {
                            setloader(false)
                            console.error("error on check in wish", err)
                        })
                }
            })
            .catch(err => {
                if (err === "logged_out") {
                    alert.show("Login to add to wishlist")
                }
                console.log('error on adding to wishlist', err)
                setloader(false)
            })
    }
    const deleteWish = (id) => {
        setloader(true)
        PContext.deleteFromWish(id)
            .then(res => {
                if (res === "item deleted") {
                    let variation_id = state.singleGalleryVariation?.data[0].id
                    PContext.checkWish(variation_id)
                        .then(resp => {
                            setListed(resp)
                            setloader(false)
                            alert.show("Removed from Wishlist", { type: "success" });
                        })
                        .catch(err => {
                            setloader(false)
                            console.error("error on check in wish", err)
                        })
                }
            })
            .catch(err => {
                setloader(false)
            })
    }
    console.log("gallery details", state.singleGalleryWall, state.singleGalleryVariation)
    return (
        <div>
            <Header />
            {loader ? <Loader /> : ''}
            <div className="header-container pt-3 pb-5">
                {state.singleGalleryWall ? state.singleGalleryWall.data ? state.singleGalleryWall.data.attributes.metatag_normalized ? state.singleGalleryWall.data.attributes.metatag_normalized.length ?
                    <MetaTags>
                        {state.singleGalleryWall.data.attributes.metatag_normalized.map((item) => {
                            if (item.tag === "meta")
                                if (item.attributes.name === "title")
                                    return (
                                        <title>{item.attributes.content}</title>
                                    )
                                else
                                    return <meta name={`${item.attributes.name}`} content={`${item.attributes.content}`} />
                            else if (item.tag === "link") {
                                if (item.attributes?.rel === "canonical")
                                    return <link rel={`${item.attributes.rel}`} href={`${window.location.href}`} />
                                else
                                    return <link rel={`${item.attributes.rel}`} href={`${item.attributes.href}`} />
                            }
                        })}
                        <meta property="og:type" content="og:product" />
                        <meta property="og:title" content={state.singleGalleryWall.data ? state.singleGalleryWall.data.attributes.title : ""} />
                        <meta property="og:image" content={state.singleGalleryWall.data.relationships.field_gallery_image.data ? base_url + state.singleGalleryWall.included.filter(item => item.id === state.singleGalleryWall.data.relationships.field_gallery_image.data.id)[0].attributes.uri.url : ""} />
                        <meta property="og:description" content={`Individual Frame Sizes: ${state.singleGalleryWall.data.attributes.field_individual_frames ? state.singleGalleryWall.data.attributes.field_individual_frames.value ? state.singleGalleryWall.data.attributes.field_individual_frames.value.replace(/<\/?[^>]+(>|$)/g, "") : "" : ""}`} />
                        <meta property="og:url" content={`/gallery_walls/${id}`} />
                        <meta property="og:site_name" content="Espravo" />
                        <meta name="twitter:card" content="" />
                        <meta name="twitter:site" content="@twitterid" />
                        <meta name="twitter:title" content={state.singleGalleryWall.data ? state.singleGalleryWall.data.attributes.title : ""} />
                        <meta name="twitter:description" content={`Individual Frame Sizes: ${state.singleGalleryWall.data.attributes.field_individual_frames ? state.singleGalleryWall.data.attributes.field_individual_frames.value ? state.singleGalleryWall.data.attributes.field_individual_frames.value.replace(/<\/?[^>]+(>|$)/g, "") : "" : ""}`} />
                        <meta name="twitter:image" content={state.singleGalleryWall.data.relationships.field_gallery_image.data ? base_url + state.singleGalleryWall.included.filter(item => item.id === state.singleGalleryWall.data.relationships.field_gallery_image.data.id)[0].attributes.uri.url : ""} />

                    </MetaTags>
                    : "" : '' : "" : ''
                }
                <Link className="mb-1 selectBack brandon d-flex align-items-center" to={`/gallery-walls/`}>
                    <img src="/assets/arrow-left.png" className="selectBackIcon" alt="thumb"></img>
                    <p className='ml-2'>Back</p>
                </Link>
                <div className="col-sm-10 col-12 px-0 mx-auto">
                    {state.singleGalleryWall && state.singleGalleryVariation ? <div className="row py-5 gallery-wall">
                        <div className="col-sm-6 pl-sm-0 col-12">
                            <div className=" select-carou">
                                <div className="col-12 col-md-11 mx-md-auto px-0 px-md-3 mb-4 mb-md-0">
                                    {state.singleGalleryWall.data.relationships.field_gallery_image.data ? <img className="gallery-img" src={state.singleGalleryWall.included.filter(item => item.id === state.singleGalleryWall.data.relationships.field_gallery_image.data.id)[0].attributes.image_style_uri.find(obj => obj.inner)?.inner} /> : ""}
                                    <div className="zoom-button-container">
                                        <img src="/assets/zoom.png" className="zoom-button" onClick={() => openZoom(true)} />
                                    </div>
                                    <Tools title={state.singleGalleryWall.data?.attributes.title} addWish={addWish} deleteWish={deleteWish} listed={listed} openZoom={openZoom} image={state.singleGalleryWall.data.relationships.field_gallery_image.data ? base_url + state.singleGalleryWall.included.find(item => item.id === state.singleGalleryWall.data.relationships.field_gallery_image.data.id)?.attributes.uri.url : ""} />
                                </div>
                                {/* <div className="col-11 brandon text-center pt-2">
                                    Images Are For Illustrative Purpose
                                </div>
                                <p className="brandon text-center">
                                    They will be replaced with images collected from you by our team after placing the order.
                                </p> */}
                            </div>
                        </div>
                        {state.singleGalleryWall.data.attributes ? <div className="col-sm-6 pl-sm-0 mt-2 mt-sm-0 col-12">
                            <h1 className="main-heading mt-3 mt-sm-0 ">
                                {state.singleGalleryWall.data ? state.singleGalleryWall.data.attributes.title : ""}
                            </h1>
                            <div className="gallery-details my-2">
                                <div className="brandon mb-2 d-flex">
                                    <p className="title">Layout Size: </p>
                                    <p className="detail pl-sm-3 pl-2">{state.singleGalleryWall.data.attributes.field_layout_size ? state.singleGalleryWall.data.attributes.field_layout_size : ""}</p>
                                </div>
                                <div className="brandon mb-2 d-flex">
                                    <p className="title">No of Frames: </p>
                                    <p className="detail pl-sm-3 pl-2">{state.singleGalleryWall.data.attributes.field_number_of_frame ? state.singleGalleryWall.data.attributes.field_number_of_frame : ""}</p>
                                </div>
                                <div className="brandon mb-2 d-flex">
                                    <p className="title">Frame: </p>
                                    <p className="detail pl-sm-3 pl-2">{state.singleGalleryWall.data.attributes.field_frame ? state.singleGalleryWall.data.attributes.field_frame : ""}</p>
                                </div>

                            </div>
                            <div className="input-pin">
                                {/* <p className="select-title">Select Location For Delivery</p> */}
                                <input id="pincode-input" value={pin} className="brandon pincode-input" onChange={(e) => checkpin(e.target.value)} placeholder="Enter Pincode For Delivery Availability" />
                            </div>
                            {
                                pin.length ? pin.length === 6 ?
                                    <p className={pinstatus && pin.length === 6 ? "green" : "red"}>{pinstatus && pin.length === 6 ? "Delivery in 5-7 days" : "Delivery Unavailable"}</p>
                                    : '' : ''
                            }
                            {state.singleGalleryVariation ? <div className="price_cart d-flex w-sm-80 w-100">
                                <p className="frame-price add-price font-weight-bold brandon">
                                    {state.singleGalleryVariation.data[0].attributes.price ? <span>Rs. <Price value={state.singleGalleryVariation.data[0].attributes.price.number} /></span> : ''}
                                </p>
                                <div onClick={() => checkAdd()} className="add-cart-button brandon">
                                    ADD TO CART
                                </div>
                            </div> : ""}
                            <div className="brandon mt-2 w-sm-80 w-100">
                                <div className="d-flex tab">
                                    <p className="description pb-1" style={{ borderBottom: tab === "description" ? "1.5px solid #1D1F22" : "", color: tab === "description" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("description")}>Description</p>
                                    {/* <p className="description ml-3 pb-1" style={{ borderBottom: tab === "review" ? "1.5px solid #1D1F22" : "", color: tab === "review" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("review")}>Read reviews</p> */}
                                    <p className="description ml-3 pb-1" style={{ borderBottom: tab === "care" ? "1.5px solid #1D1F22" : "", color: tab === "care" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("care")}>Care Instructions</p>
                                    <p className="description ml-3 pb-1" style={{ borderBottom: tab === "return" ? "1.5px solid #1D1F22" : "", color: tab === "return" ? "#1D1F22" : "#8D8F9A" }} onClick={() => settab("return")}>Return Policy</p>
                                </div>
                                <div className="pl-1 py-2">
                                    {tab == "description" ? <p>Individual Frame Sizes:</p> : ""}
                                    {tab == "description" ? <p className="">{state.singleGalleryWall.data.attributes.field_individual_frames ? state.singleGalleryWall.data.attributes.field_individual_frames.value ? HTMLReactParser(state.singleGalleryWall.data.attributes.field_individual_frames.value) : "" : ""}</p> : ""}
                                    {tab === "review" ? <p>There are no review for this products</p> : ""}
                                    {tab === "care" ? <p>{HTMLReactParser(care.attributes.body ? care.attributes.body.value : "No care instructiion for this product")}</p> : ""}
                                    {tab === "return" ? <p>{HTMLReactParser(policy.attributes.body ? policy.attributes.body.value : "No Return Policy for this product")}</p> : ""}
                                </div>
                            </div>
                        </div> : ""}


                    </div> : <div className='container py-5 my-5'> <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} /></div>
                    }
                </div>
                {zoom ? <div className='overlay zoom-image-overlay'></div> : ""}
                <Popup className="zoompopup" open={zoom} onClose={() => openZoom(false)}>
                    <div className="d-flex zoompopup-container flex-column ">
                        <img className="zoom-close cursor ml-auto mb-3 d-flex justify-content-end" role="button" src="/assets/contactclose.png" onClick={() => openZoom(false)} />
                        {state.singleGalleryWall ? state.singleGalleryWall.data.relationships.field_gallery_image.data ? <img className="gallery-img zoom-img" src={base_url + state.singleGalleryWall.included.filter(item => item.id === state.singleGalleryWall.data.relationships.field_gallery_image.data.id)[0].attributes.uri.url} /> : ""
                            : ""
                        }
                    </div>
                </Popup>
                <Popup open={add} onClose={() => openAdd(false)}>
                    <div className="alert-popup">
                        <img className="zoom-close cursor ml-auto d-flex justify-content-end" role="button" src="/assets/contactclose.png" onClick={() => openAdd(false)} />
                        <div className="GT alert-heading">
                            Added to cart
                        </div>
                        <p className="brandon alert-desc">
                            Product successfully added to your shopping cart.
                        </p>
                        <div className="d-flex">
                            <div className="alert-continue cursor py-2 brandon" onClick={() => openAdd(false)}>Continue shopping</div>
                            <div className="alert-proceed py-2 cursor brandon" onClick={() => { history.push('/checkout/shopping-bag/'); }}>Proceed to checkout</div>
                        </div>
                    </div>
                </Popup>
            </div>
            <Footer />
        </div>
    )

}
export default GalleryWall