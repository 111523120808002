import React, { useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/Footer/footer";
import { Context as ProductContext } from '../../context/ProductContext';
import { Link, useParams } from "react-router-dom";
import { Context as UserContext } from '../../context/UserContext';
import Loading from "react-js-loader";
import Price from '../../helpers/price';
import "./objects.css"
import { useEffect, useContext } from "react";
import { MetaTags } from "react-meta-tags";
import ReactPaginate from "react-paginate";
import { useAlert } from 'react-alert';
import createHistory from 'history/createBrowserHistory'
import Loader from "../../components/checkout/loader";



export const objects = [
    { title: "The Fab Four", material: "Metal", img: "/assets/objects/object1.png", price: "2500", path: "/decor-objects/the-fab-four/", color: "Off White w/ Speckles", size: "42 x 42" },
    { title: "The Playful Eclectic", material: "Metal", img: "/assets/objects/object2.png", price: "3500", path: "/decor-objects/the-playful-eclectic/", color: "Brown", size: "38 x 42" },
    { title: "The Memoir", material: "Metal", img: "/assets/objects/object3.png", price: "4000", path: "/decor-objects/the-memoir/", color: "White", size: "42 x 40" },

]
const history = createHistory()

const ObjectListing = () => {
    const { state, getObjects, clearObjects, addToWish, deleteFromWish } = useContext(ProductContext);
    const alert = useAlert()
    let params = useParams();
    let UContext = useContext(UserContext)
    const [loader, setloader] = useState(false)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(30)
    const [pageno, setPageNo] = useState(0)
    const [gotoPage, setGotoPageNo] = useState("")
    const [valid, setValid] = useState(true)
    const [hover, setHover] = useState(null)
    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            let page = location.pathname.match(/\d+/g)?.length ? location.pathname.match(/\d+/g)[0] : ""
            console.log("changing", page)
            window.scrollTo(0, 0)
            let offset = (page - 1) * limit
            if (page) {
                setPageNo(page - 1)
                // setGotoPageNo(`${page}`)
                getObjects(offset, limit)
            }
            else {
                setPageNo(0)
                // setGotoPageNo("1")
                getObjects(0, limit)
            }
            clearObjects()
        });
        // Cleanup function to remove the listener when the component unmounts
        return () => {
            unlisten();
        };

    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (params.id) {
            let offset = (Number(params.id) - 1) * limit
            setPageNo(Number(params.id) - 1)
            // setGotoPageNo(params.id)
            getObjects(offset, limit)
        }
        else
            getObjects(0, limit)
    }, [])
    const handlePageClick = (e) => {
        console.log("clicked", e)
        if (e.nextSelectedPage === 0) {
            history.push("/decor-objects/")
        }
        else {
            history.push(`/decor-objects/${e.nextSelectedPage + 1}/`)
        }
    }
    const setPageChange = (action) => {
        if (action === "prev") {
            let page = pageno - 1
            if (page === 0) {
                history.push("/decor-objects/")
            }
            else {
                history.push(`/decor-objects/${page + 1}/`)
            }

        }
        else if (action === "next") {
            let page = pageno + 1
            if (page === 0) {
                history.push("/decor-objects/")
            }
            else {
                history.push(`/decor-objects/${page + 1}`)
            }
        }
    }
    const GoTo = () => {
        if (Number(gotoPage) <= Number(state.objects.count) && Number(gotoPage) !== 0) {
            if (Number(gotoPage) - 1 !== pageno) {
                if (Number(gotoPage) == 1) {
                    history.push("/decor-objects/")
                }
                else {
                    history.push(`/decor-objects/${gotoPage}/`)
                }
            }
            else {
                alert.show(`Already on page ${pageno + 1}`)
            }
        }
        else {
            console.log("enter a valid page")
            alert.show("Enter a valid number")
        }
    }
    const setpage = (e) => {
        console.log("entering", e.target.value)
        const re = /^[0-9\b]+$/;
        if (Number(e.target.value) >= Number(e.target.min) || Number(e.target.value) <= Number(e.target.max)) {
            setValid(false)
            setGotoPageNo(e.target.value)
        }
        else {
            e.preventDefault();
        }
    }
    useEffect(() => {
        getObjects()
        window.scrollTo(0, 0)
        if (localStorage.getItem("userdat"))
            UContext.getWishlist()
    }, [])

    const checkClick = (e) => {
        if (e.target.className.includes("heart-symbol") || e.target.className.includes("heart-box") || e.target.className.includes("plus-symbol"))
            e.preventDefault()
    }

    const addWish = (id) => {
        setloader(true)
        addToWish(id)
            .then(res => {
                if (res === "item added") {
                    alert.show("Added to Wishlist", { type: "success" });
                    UContext.getWishlist()
                    setloader(false)
                }
            })
            .catch(err => {
                if (err === "logged_out") {
                    alert.show("Login to add to wishlist")
                    setloader(false)
                }
                console.log('error on adding to wishlist', err)
                setloader(false)
            })
    }
    const deleteWish = (id) => {
        setloader(true)
        deleteFromWish(id)
            .then(res => {
                if (res === "item deleted") {
                    alert.show("Removed from Wishlist", { type: "success" });
                    UContext.getWishlist()
                    setloader(false)
                }
            })
            .catch(err => {
                setloader(false)
            })
    }
    return (<div className="object-listing">
        {loader ? <Loader /> : ""}
        <MetaTags>
            <title>Shop The Best Home Decor Objects Online I Espravo</title>
            <meta name="description" content="Style your home interiors with artful decorative objects. Shop now at Espravo." />
            <meta name="keywords" content="decorative objects, home decor objects, interior styling, creative home styling, curios, artefacts, vases, metal, glass, ceramics, terracotta, flower vase, decorative bowls, sculpture, figurines, candles, dry botanicals" />
            <link rel="canonical" href={`${window.location.origin}/decor-objects/`} />
        </MetaTags>
        <Header />
        <div className="row mixed-media-banner mt-0">
            <div className="gift-heading col-sm-6 col-12 d-flex flex-column align-items-center justify-content-center">
                <h1 className="main-heading mb-1 mt-sm-0 mt-3 text-center">
                    Decor Objects
                </h1>
                <p className="brandon">Style your home interiors with our handcrafted collection of decor objects.</p>
            </div>
            <div className="col-sm-6 px-0 col-12">
                <div className="mixed-media-banner-img">
                    <img src="/assets/objects/banner.jpg" />
                </div>
            </div>
        </div>
        <div className="header-container gallery-select my-sm-5 my-3 py-sm-5 py-3">
            <div className='d-flex justify-content-between col-12 px-0 mb-sm-5 mb-3'>
                {state.objects?.count ? <div className='d-flex col-2 ml-auto justify-content-end align-items-center px-0 text-right brandon filteredResults'>
                    {pageno == 0 ? "" : <img src="/assets/countright.png" onClick={(e) => { setPageChange("prev") }} className='page-icon m-0 left-icon' />}&nbsp;  <span> Page&nbsp;{pageno + 1}&nbsp; of {state.objects.count}</span> &nbsp;{pageno + 1 < state.objects.count ? <img src="/assets/countright.png" onClick={(e) => { setPageChange("next") }} className='page-icon right-icon m-0' /> : ""}
                </div> : ""}
            </div>
            <div className="frame-container mx-xl-5 mx-md-2 mx-0 px-sm-0">
                <div className="frame-list justify-content-center row mx-sm-4">
                    {state.objects ? state.objects.data?.map((object) => {
                        let variation = object.relationships.default_variation.data ? state.objects.included?.find(x => x.id === object.relationships.default_variation.data.id) : null
                        let material = object.relationships.field_obejct_material.data ? state.objects.included?.find(x => x.id === object.relationships.field_obejct_material.data.id)?.attributes.name : ""
                        let price = object.relationships.default_variation.data ? state.objects.included?.find(x => x.id === object.relationships.default_variation.data.id)?.attributes.price?.number : "",
                            media = variation?.relationships.field_image?.data.length ? state.objects.included.find(x => x.id === variation.relationships.field_image.data[0]?.id)?.relationships.thumbnail.data?.id : ""
                        let image = media ? state.objects.included?.find(x => x.id === media).attributes.image_style_uri.find(x => x.listing)?.listing : "",
                            // path = `/objects/${object.attributes.title?.toLowerCase().replace(/ /g, "-")}/`
                            path = object.attributes.path?.alias.replace("object/", "decor-objects/")
                        let listed = UContext.state.wishlist?.wishlist_variation?.find(x => x.relationships.purchasable_entity?.data?.id === variation?.id)
                        return (
                            <Link to={`${path}/`} onMouseEnter={() => setHover(object.id)} onMouseLeave={() => setHover(null)} onClick={(e) => { checkClick(e); localStorage.setItem("objects_back_path", `/decor-objects/${pageno ? `${pageno + 1}/` : ""}`) }} className="frame-ind col-6 py-md-5 py-3 col-md-8 col-sm-4 px-xl-5 px-lg-3 px-md-4 mb-4">
                                <img className="pb-sm-3 p-2 h-auto" alt="icon" src={image ? image : "/assets/meticulous.png"} />
                                <div className="name-price mt-1">
                                    <p className="sub-heading GT-bold text-center">
                                        {object.attributes.title}
                                    </p>
                                    <p className="brandon text-center secondarycolor">
                                        <span>{material}{material ? " | " : ""}</span><span>Rs. <Price value={price} /></span>
                                    </p>
                                </div>
                                {listed ?
                                    <div className='heart-box listed' onClick={() => deleteWish(listed?.id)}>
                                        <img className="heart-symbol listed" src='/assets/gray-heart.png' />
                                    </div>
                                    : hover === object.id || window.innerWidth < 992 ? <div className='heart-box' onClick={() => addWish(variation?.id)}>
                                        <img className="heart-symbol" src='/assets/heart.png' />
                                    </div>
                                        : ""}
                            </Link>
                        )
                    }
                    ) : <div className='container py-5 my-5'> <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} /></div>}
                    <div className='container brandon d-lg-flex d-block'>
                        {state.objects?.count > 1 ?
                            <ReactPaginate
                                nextLabel={<img src="/assets/countright.png" className='page-icon right-icon' />}
                                // onPageChange={handlePageClick}
                                onClick={handlePageClick}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={1}
                                pageCount={state.objects?.count}
                                previousLabel={<img src="/assets/countright.png" className='page-icon left-icon' />}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item arrow"
                                previousLinkClassName="page-link arrow"
                                nextClassName="page-item arrow"
                                nextLinkClassName="page-link arrow"
                                breakLabel="..."
                                breakClassName="dots"
                                breakLinkClassName="page-link"
                                containerClassName="pagination justify-content-center"
                                activeClassName="active"
                                activeLinkClassName="activePage"
                                renderOnZeroPageCount={null}
                                forcePage={Number(pageno)}
                            />
                            :
                            ""}
                        <div className='goto-container d-flex flex-column justify-content-end ml-auto'>
                            <div className='d-flex justify-content-lg-end justify-content-center align-items-center'>
                                <p className='brandon'>Go to page:</p>
                                <input className='page_input' min="1" max={state.objects?.count} value={gotoPage || ""} onChange={(e) => { setpage(e) }} />
                                <button className='bgsecondary whitecolor px-3 pt-1' onClick={() => GoTo()}>Go</button>
                            </div>
                            {/* {valid ? <p className='text-danger brandon'>Enter a valid number</p> : ""} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>)
}
export default ObjectListing;