import React, { useContext, useEffect, useState } from "react"
import { Context as ArtContext } from '../../context/ArtContext';
import { Context as UserContext } from '../../context/UserContext';
import { Context as ProductContext } from '../../context/ProductContext';
import Footer from "../../components/Footer/footer"
import Header from "../../components/header/header"
import "./gifting.css"
import Loading from "react-js-loader";
import { base_url } from "../../helpers/baseURL";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import Price from "../../helpers/price";
import { MetaTags } from "react-meta-tags";
import { useAlert } from "react-alert";
import Loader from "../../components/checkout/loader";
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
const GiftingList = () => {
    const history = useHistory();
    const query = useQuery()
    let params = useParams();
    const { state, getGifting } = useContext(ArtContext);
    let UContext = useContext(UserContext)
    let PContext = useContext(ProductContext)
    const alert = useAlert()

    const [page, setPage] = useState(params.id ? params.id : 1)
    const [loader, setloader] = useState(false)
    const [hover, setHover] = useState(null)
    useEffect(() => {
        console.log("pages", params.id)
        if (localStorage.getItem("userdat"))
            UContext.getWishlist()
        if (params.id) {
            setPage(params.id)
        }
        else {
            console.log("setting page query")
            history.push("/gifts/")
        }
        getGifting()
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [params.id])
    const checkClick = (e) => {
        if (e.target.className.includes("heart-symbol") || e.target.className.includes("heart-box") || e.target.className.includes("plus-symbol"))
            e.preventDefault()
    }

    const addWish = (id) => {
        setloader(true)
        PContext.addToWish(id)
            .then(res => {
                if (res === "item added") {
                    alert.show("Added to Wishlist", { type: "success" });
                    UContext.getWishlist()
                    setloader(false)
                }
            })
            .catch(err => {
                if (err === "logged_out") {
                    alert.show("Login to add to wishlist")
                }
                console.log('error on adding to wishlist', err)
                setloader(false)
            })
    }
    const deleteWish = (id) => {
        setloader(true)
        PContext.deleteFromWish(id)
            .then(res => {
                if (res === "item deleted") {
                    alert.show("Removed from Wishlist", { type: "success" });
                    UContext.getWishlist()
                    setloader(false)
                }
            })
            .catch(err => {
                setloader(false)
            })
    }
    return (
        <div>
            {loader ? <Loader /> : ""}
            <MetaTags>
                <title>Buy The Best Personalised Photo Frame Gifts for Every Occasion at Espravo</title>
                <meta name="keywords" content="best photo frame gift, customised photo frame gift, photo frame for gift, photo frame gift online, picture frame gift, wall painting for gift" />
                <meta name="description" content="We at Espravo offer the best photo frame gifts for your loved ones for every occasion. The collection include striking art prints & custom framed photos. Buy Online!" />
                <link rel="canonical" href={`${window.location.origin}/gifts/`} />
            </MetaTags>
            <Header />
            <div className="giftingList-page">
                <div className="row gift-main-banner">
                    <div className="gift-heading col-sm-6 col-12 d-flex flex-column align-items-center justify-content-center">
                        <h2 className="main-heading mb-1 mt-sm-0 mt-3 text-center">
                            Custom Framed Gifts
                        </h2>
                        <p className="brandon">Unique gifts perfect for celebrations of all kinds.</p>
                    </div>
                    <div className="col-sm-6 px-0 col-12">
                        <div className="gifting-banner">

                            <img src="/assets/gifting/banner.png" />
                        </div>
                    </div>
                </div>
                {params.id === "1" || params.id == "0" || !params.id ? <div className="frame-container gift-list-container">
                    <div className="frame-list gallery-list row mx-sm-4">
                        {state.giftingList ? state.giftingList.art ? state.giftingList.art.data.map((gifting, ind) => {
                            let price = gifting.relationships.default_variation.data ? state.giftingList.art.included.filter(item => item.id === gifting.relationships.default_variation.data.id)[0].attributes.price.number : ""
                                , path = gifting.attributes?.field_path_alias,
                                media = gifting.relationships.field_gifting_image.data.length ? state.giftingList.art.included.filter(item => item.id === gifting.relationships.field_gifting_image.data[0].id).length ? state.giftingList.art.included.filter(item => item.id === gifting.relationships.field_gifting_image.data[0].id) : [] : []
                            let variable = gifting.relationships?.default_variation?.data?.id
                            let listed = UContext.state.wishlist?.wishlist_variation?.find(x => x.relationships.purchasable_entity?.data?.id === variable)
                            if (ind < 12)
                                return (
                                    <Link to={`${path}/`} onMouseEnter={() => setHover(gifting.id)} onMouseLeave={() => setHover(null)} onClick={(e) => { checkClick(e); localStorage.setItem("gift_back", page) }} className={`gifting-block col-6 col-md-8 col-sm-4 mb-4 mx-0`}>
                                        <div className="gifting-imageContainer">
                                            {media.length ? <img className="" alt="icon" src={media[0].attributes.image_style_uri.find(obj => obj.inner).inner} /> : ""}
                                            <p className="price-box brandon">&#8377; {price ? <Price value={price} /> : ""}</p>
                                        </div>
                                        <div className="name-price mt-3">
                                            <p className="gift-title GT-bold text-center">
                                                {gifting.attributes.title ? gifting.attributes.title : ""}
                                            </p>
                                            <p className="brandon text-center secondarycolor gift-frameName">
                                                {gifting.attributes.field_frame ? gifting.attributes.field_frame : ""}
                                            </p>
                                            {listed ?
                                                <div className='heart-box listed' onClick={() => deleteWish(listed?.id)}>
                                                    <img className="heart-symbol listed" src='/assets/gray-heart.png' />
                                                </div>
                                                : hover === gifting.id || window.innerWidth < 992 ? <div className='heart-box' onClick={() => addWish(variable)}>
                                                    <img className="heart-symbol" src='/assets/heart.png' />
                                                </div>
                                                    : ""}
                                        </div>
                                    </Link>
                                )
                        }
                        ) : "" : <div className='container py-5 my-5'> <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} /></div>}
                    </div>
                </div>
                    : ""}
                {params.id === "2" ? <div>
                    <div className="frame-container gift-list-container">
                        <div className="frame-list gallery-list row mx-sm-4">
                            {state.giftingList ? state.giftingList.art ? state.giftingList.art.data.map((gifting, ind) => {
                                let price = gifting.relationships.default_variation.data ? state.giftingList.art.included.filter(item => item.id === gifting.relationships.default_variation.data.id)[0].attributes.price.number : ""
                                    , path = gifting.attributes?.field_path_alias,
                                    media = gifting.relationships.field_gifting_image.data.length ? state.giftingList.art.included.filter(item => item.id === gifting.relationships.field_gifting_image.data[0].id).length ? state.giftingList.art.included.filter(item => item.id === gifting.relationships.field_gifting_image.data[0].id) : [] : []
                                let variable = gifting.relationships?.default_variation?.data?.id
                                let listed = UContext.state.wishlist?.wishlist_variation?.find(x => x.relationships.purchasable_entity?.data?.id === variable)
                                if (ind > 11)
                                    return (
                                        <Link to={`${path}/`} onMouseEnter={() => setHover(gifting.id)} onMouseLeave={() => setHover(null)} onClick={(e) => { checkClick(e); localStorage.setItem("gift_back", page) }} className={`gifting-block col-6 col-md-8 col-sm-4 mb-4 mx-0`}>
                                            <div className="gifting-imageContainer">
                                                {media.length ? <img className="" alt="icon" src={media[0].attributes.image_style_uri.find(obj => obj.inner).inner} /> : ""}
                                                <p className="price-box brandon">&#8377; {price ? <Price value={price} /> : ""}</p>
                                            </div>
                                            <div className="name-price mt-3">
                                                <p className="gift-title GT-bold text-center">
                                                    {gifting.attributes.title ? gifting.attributes.title : ""}
                                                </p>
                                                <p className="brandon text-center secondarycolor gift-frameName">
                                                    {gifting.attributes.field_frame ? gifting.attributes.field_frame : ""}
                                                </p>
                                                {listed ?
                                                    <div className='heart-box listed' onClick={() => deleteWish(listed?.id)}>
                                                        <img className="heart-symbol listed" src='/assets/gray-heart.png' />
                                                    </div>
                                                    : hover === gifting.id || window.innerWidth < 992 ? <div className='heart-box' onClick={() => addWish(variable)}>
                                                        <img className="heart-symbol" src='/assets/heart.png' />
                                                    </div>
                                                        : ""}
                                            </div>
                                        </Link>
                                    )
                            }
                            ) : "" : <div className='container py-5 my-5'> <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} /></div>}
                        </div>
                    </div>
                    <div className="header-container">
                        <div className="row bg-white">
                            <div className="gift-heading col-sm-6 col-12 d-flex flex-column align-items-center justify-content-center">
                                <h2 className="main-heading mb-1 mt-sm-0 mt-3 text-center">
                                    Thoughtful Gifts
                                </h2>
                                <p className="brandon">Commemorate your best moments with your loved ones<br />
                                    with a custom framed photo.</p>
                            </div>
                            <div className="col-sm-6 px-0 col-12">
                                <div className="full-banner-container">
                                    <img src="/assets/gifting/full-banner.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-container gift-list-container">
                        <div className="frame-list gallery-list row mx-sm-4">
                            {state.giftingList ? state.giftingList.custom ? state.giftingList.custom.data.map((gifting, ind) => {
                                let price = gifting.relationships.default_variation.data ? state.giftingList.custom.included.filter(item => item.id === gifting.relationships.default_variation.data.id)[0].attributes.price.number : "",
                                    path = gifting.attributes?.field_path_alias,
                                    media = gifting.relationships.field_gifting_image.data.length ? state.giftingList.custom.included.filter(item => item.id === gifting.relationships.field_gifting_image.data[0].id).length ? state.giftingList.custom.included.filter(item => item.id === gifting.relationships.field_gifting_image.data[0].id) : [] : []
                                let variable = gifting.relationships?.default_variation?.data?.id
                                let listed = UContext.state.wishlist?.wishlist_variation?.find(x => x.relationships.purchasable_entity?.data?.id === variable)
                                return (
                                    <Link to={`${path}/`} onMouseEnter={() => setHover(gifting.id)} onMouseLeave={() => setHover(null)} onClick={(e) => { checkClick(e); localStorage.setItem("gift_back", page) }} className={`gifting-block col-6 col-md-8 col-sm-4 mb-4 mx-0`}>
                                        <div className="gifting-imageContainer">
                                            {media.length ? <img className="" alt="icon" src={media[0].attributes.image_style_uri.find(obj => obj.inner)?.inner} /> : ""}
                                            <p className="price-box brandon">&#8377; {price ? <Price value={price} /> : ""}</p>
                                        </div>
                                        <div className="name-price mt-3">
                                            <p className="gift-title GT-bold text-center">
                                                {gifting.attributes.title ? gifting.attributes.title : ""}
                                            </p>
                                            <p className="brandon text-center secondarycolor gift-frameName">
                                                {gifting.attributes.field_frame ? gifting.attributes.field_frame : ""}
                                            </p>
                                            {listed ?
                                                <div className='heart-box listed' onClick={() => deleteWish(listed?.id)}>
                                                    <img className="heart-symbol listed" src='/assets/gray-heart.png' />
                                                </div>
                                                : hover === gifting.id || window.innerWidth < 992 ? <div className='heart-box' onClick={() => addWish(variable)}>
                                                    <img className="heart-symbol" src='/assets/heart.png' />
                                                </div>
                                                    : ""}
                                        </div>
                                    </Link>
                                )
                            }
                            ) : "" : <div className='container py-5 my-5'> <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} /></div>}
                        </div>
                    </div>
                </div> : ""}
                <div className="d-flex justify-content-center">
                    <div className={`gifting-button brandon ${params.id === "2" ? "active" : "d-none"}`} onClick={() => { history.push("/gifts/"); setPage(1) }}>Previous</div>
                    <div className={`gifting-button brandon ${params.id === "1" || params.id == "0" || !params.id ? "active" : "d-none"}`} onClick={() => { history.push("/gifts/2/"); setPage(2) }}>Next</div>
                </div>
            </div>
            <Footer />
        </div >
    )
}
export default GiftingList;