import React, { useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/header/header";
// import "./Partnerapply.css";
import { useHistory } from "react-router"
import axios from "axios";
import { useAlert } from "react-alert";
import { base_url } from "../../helpers/baseURL";
import Loader from "../../components/checkout/loader";
import "./Designart.css"


export const Designart = () => {

    // let  base64code= [];
    const [base64code, set64array] = useState([]);


    const history = useHistory()
    const alert = useAlert();
    const [loader, setloader] = useState(false);
    const [imgs, setimgs] = useState(false)
    const [imgarray, setarry] = useState([]);


    const imgcoverter = (e) => {
        console.log(e.target.files[0].name);
        // const img=e.target.file[0].name;
        const file = e.target.files[0];
        setarry(oldArray => [...oldArray, file.name])
        getbase64(file)

    }

    const onLoad = (filestring) => {
        // base64code = filestring
        // base64code.push(filestring);
        set64array(oldArray => [...oldArray, filestring]);
    }

    const getbase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = () => {
            onLoad(reader.result);
        }
    }

    const removeImg = (item) => {
        // const temp = imgarray;
        // if (ind > -1) { // only splice array when item is found
        //     temp.splice(ind, 1); // 2nd parameter means remove one item only
        // }
        // console.log(temp)
        // setarry(temp)
        // setarry(oldArray => [oldArray, temp]);

        // setarry(imgarray.filter((item, index) => index == ind));
        setarry(imgarray.filter(img => img !== item));
    }


    const formsubmit = (e) => {
        console.log(base64code)
        setloader(true)
        const fname = document.getElementById("fname").value;
        const lname = document.getElementById("lname").value;
        const email = document.getElementById("email").value;
        const phone = document.getElementById("phone").value;
        const address = document.getElementById("address").value;
        const city = document.getElementById("city").value;
        const state = document.getElementById("state").value;
        const pin = parseInt(document.getElementById("pin").value);
        const about = document.getElementById("about").value;
        const img = base64code;
        console.log(fname)
        console.log(lname)
        console.log(email)
        console.log(about)
        console.log(img)
        console.log(phone)
        console.log(address)
        console.log(city)
        console.log(state)
        console.log(pin)

        var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
        var mobileno = /^((\\+91-?)|0)?[0-9]{10}$/;



        if (fname && about && phone && address && city && state && pin && phone && email) {

            if ((!phone.match(phoneno)) || (!phone.match(mobileno))) {
                setloader(false)
                alert.show("Enter Valid Phone Number")

            }
            else {

                if (!img) {
                    setloader(false)
                    alert.show("Photo not uploaded")
                }
                else {
                    var axios = require('axios');
                    var data = img;

                    var config = {
                        method: 'post',
                        url: `${base_url}/jsonapi/media/image/field_media_image`,
                        headers: {
                            'Accept': 'application/vnd.api+json',
                            'Content-Type': 'application/octet-stream',
                            'X-CSRF-Token': 'anOsmfJ2U1DlJP6DKwIKZ1IAP62zC2_rynvGLwdREFQ',
                            'Content-Disposition': 'file;filename="maxresdefault.jpg"',
                            'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImM1YWNiYmYzNjNjNjlkZDU4YmIxMDE0NTY5MGEwZDNhNWFkYjg1NmFhNGQxYjlmNzNmZjhhOTZhZDYxMDEyMzE1Yzk2MWQ4ZTRjYTgzYTc5In0.eyJhdWQiOiI4ZDUyYWFhYS1lNjgwLTQzYjgtOGZiZS0zNGIzYTAxNTIwYmIiLCJqdGkiOiJjNWFjYmJmMzYzYzY5ZGQ1OGJiMTAxNDU2OTBhMGQzYTVhZGI4NTZhYTRkMWI5ZjczZmY4YTk2YWQ2MTAxMjMxNWM5NjFkOGU0Y2E4M2E3OSIsImlhdCI6MTYxNzY4MTcwOSwibmJmIjoxNjE3NjgxNzA5LCJleHAiOjE2MjA4OTEzMDksInN1YiI6IjEiLCJzY29wZXMiOlsiYXV0aGVudGljYXRlZCIsInVzZXIiXX0.ukbSQMsiugiqkzeB3DYX8wPUmg4JeDUf822TUqtCplIYIC_P4kPP-rq1YnD4pPfWMQXWwqCVDvmUmWwJQVcj_cJ5rr1du6Ux4D8vlAyrsWukwYRoPmUlTfpkmJzmtYQO9LzKGoeKJJPOrYhuCQpF2LeoRCJpgqrATb53R4VvOxOM-T8naFNDdD_CRWNbsCLkAI8Vq2oM_pHfyn4-vRVs0iSW52Ogm53LvjxPqokhU8n7Jzu6bpY2MUdm1TciHaKm9Rw5PEMJTWSe9DJMKNPtvICKEz4veSs0hCLwLb4TO35cp-EXF3GoS_e560UdG3xavDvLoNBDkL1MHbFDkyXrmA',
                            'Cookie': 'SSESSec5a92862a48f121bdb88ebb0ed1b008=4CYfssdwKWaZSX3I8fmNMbuuFlNeAOEtLO7Re0aymuA'
                        },
                        data: data
                    };

                    axios(config)
                        .then(function (response) {
                            console.log(JSON.stringify(response.data.data.id));
                            var data = JSON.stringify({
                                "data": {
                                    "type": "paragraph--author_data",
                                    "attributes": {
                                        "parent_id": "37",
                                        "parent_type": "node",
                                        "parent_field_name": "field_image_of_art",
                                        "field_published": false,
                                        "field_comment": "uuu"
                                    },
                                    "relationships": {
                                        "field_image_of_form": {
                                            "data": {
                                                "type": "file--file",
                                                "id": response.data.data.id,
                                            }
                                        }
                                    }
                                }
                            });

                            var config = {
                                method: 'post',
                                url: `${base_url}/jsonapi/paragraph/form_image`,
                                headers: {
                                    'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjcwZTExMzAwOGNlNjAxZjc4ZGQxOWZmY2ExOWYzMzU5NzI4YzE3OTU0Y2U0MzAyMDQzODkwYzMxZTIyNWU3ZDdkNmE1MmVlOTczNGM4ZTc3In0.eyJhdWQiOiJhYjE1NzU1Yy00N2Y3LTQ2OTktYjMwNi03NWY2YTU4YzAwYjciLCJqdGkiOiI3MGUxMTMwMDhjZTYwMWY3OGRkMTlmZmNhMTlmMzM1OTcyOGMxNzk1NGNlNDMwMjA0Mzg5MGMzMWUyMjVlN2Q3ZDZhNTJlZTk3MzRjOGU3NyIsImlhdCI6MTYzNDY0NTY2OSwibmJmIjoxNjM0NjQ1NjY5LCJleHAiOjE2MzQ2NDc0NjksInN1YiI6IjgzNiIsInNjb3BlcyI6WyJhdXRoZW50aWNhdGVkIl19.rdAQ8qqHFvaUUPQmRORCnzMo9icgDFMQEiW3ADC7aNwpfi1YAuRsoZig_cWDxbob828lb6WuFhrEbBBfzEC-8JFvmN8fHLLdesnsd44jb6eR6CTxyHSZup8B8r2ItZ_3GQXpx6GMS4Qtql9dfSADLFxBHuyb_nl3vuLcPWUDgfF6m5RtqF5ENZa8AJTCOw-UQb70K2fN7-D_ehOilZNmUUkmiC1POPVrZxvG2iCg__QKB906kZM_Uc5UQ4mhss-LkfrM-EicXZV337JY-zBzVQqAk31my31o4LouuzTfwXqYd24WfEM_hBM7NZ64XWQEyfw2wuZ1VmF2R781NMxxhA',
                                    'Content-Type': 'application/vnd.api+json',
                                    'Accept': 'application/vnd.api+json',
                                    'Cookie': 'SSESSec5a92862a48f121bdb88ebb0ed1b008=4CYfssdwKWaZSX3I8fmNMbuuFlNeAOEtLO7Re0aymuA'
                                },
                                data: data
                            };

                            axios(config)
                                .then(function (response) {
                                    console.log(JSON.stringify(response.data.data.attributes.drupal_internal__id));
                                    var data = JSON.stringify({
                                        "data": {
                                            "type": "contact_message--art_advisory",
                                            "attributes": {
                                                "name": fname,
                                                "mail": email,
                                                "subject": "art advisory",
                                                "message": about,
                                                "field_address_art_advisory": address,
                                                "field_city_art": city,
                                                "field_last_name": lname,
                                                "field_phone_number_art": phone,
                                                "field_pin_code": pin,
                                                "field_state_art": state
                                            },
                                            "relationships": {
                                                "field_image_of_art": {
                                                    "data": [
                                                        {
                                                            "type": "paragraph--form_image",
                                                            "id": response.data.data.id,
                                                            "meta": {
                                                                "target_revision_id": parseInt(response.data.data.attributes.drupal_internal__id)
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    });

                                    var config = {
                                        method: 'post',
                                        url: `${base_url}/jsonapi/contact_message/art_advisory`,
                                        headers: {
                                            'Accept': 'application/vnd.api+json',
                                            'Content-Type': 'application/vnd.api+json',
                                            'Cookie': 'SSESSec5a92862a48f121bdb88ebb0ed1b008=4CYfssdwKWaZSX3I8fmNMbuuFlNeAOEtLO7Re0aymuA'
                                        },
                                        data: data
                                    };

                                    axios(config)
                                        .then(function (response) {
                                            console.log("success");
                                            setloader(false)
                                            alert.show("Form submitted")
                                            // document.getElementById("designart-form").reset();
                                            // history.push("/")
                                        })
                                        .catch(function (error) {
                                            setloader(false)
                                            alert.show("Form not submitted")
                                        });


                                })
                                .catch(function (error) {
                                    setloader(false)
                                    alert.show("Photo not uploaded")
                                });
                        })
                        .catch(function (error) {
                            setloader(false)
                            alert.show("Photo not uploaded");
                        });
                }

            }
        }
        else {
            setloader(false)
            alert.show("Enter All Details")
        }



    }

    return (
        <div className="designart-container">
            <div className="partner-container designart-conrtainer">
                <Header />
                {loader ? <Loader /> : ""}
                <div className="header-container">
                    <div className="selectBack pt-3 brandon d-flex align-self-left" onClick={() => history.goBack()}>
                        <img src="/assets/arrow-left.png" className="selectBackIcon" alt="thumb"></img>
                        <p className='ml-2'>Back</p>
                    </div>
                </div>
                <div className="container partnerapply-container">
                    <div className="partner-title art-love text-center">
                        Design Services - Art Advisory
                    </div>
                    <div className="partner-subtitle partner-subtitle1 text-center brandon">
                        Please fill out the form below
                    </div>
                    <form id="designart-form" className="d-flex flex-column partner-form mx-auto" onSubmit={(e) => { e.preventDefault(); formsubmit(e) }}>
                        <>
                            <label className="partner-inputlabel brandon">First Name</label>
                            <input name="First Name" id="fname" className="partner-input" placeholder="" />
                        </>

                        <>
                            <label className="partner-inputlabel brandon">Last Name</label>
                            <input name="Last Name" id="lname" className="partner-input" placeholder="" />
                        </>
                        <>
                            <label className="partner-inputlabel brandon">Email</label>
                            <input name="Email" id="email" className="partner-input" placeholder="" type={"email"} />
                        </>
                        <>
                            <label className="partner-inputlabel brandon">Phone Number</label>
                            <input name="Phone Number" id="phone" className="partner-input" placeholder="" />
                        </>


                        <label className="partner-inputlabel brandon">Address</label>
                        <input name="Address" id="address" className="partner-input" placeholder="" />



                        <div className="d-lg-flex text-center designartaddress-container mx-auto justify-content-between">
                            <div className="text-left designart-citycontainer">
                                <label className="partner-inputlabel brandon w-100">City</label>
                                <input name="City" id="city" className="partner-input mr-0 ml-0" placeholder="" />
                            </div>
                            <div className="text-left designart-citycontainer">
                                <label className="partner-inputlabel brandon w-100 ">State</label>
                                <input name="State" id="state" className="partner-input mr-0 ml-0" placeholder="" />

                            </div>
                            <div className="text-left designart-citycontainer">
                                <label className="partner-inputlabel brandon w-100">Pin Code</label>
                                <input name="Pin Code" id="pin" className="partner-input mr-0 ml-0" placeholder="" />
                            </div>

                        </div>
                        <label className="partner-inputlabel brandon">Tell us about your project</label>
                        <textarea type={"text"} name="about" id="about" className="partner-input partner-inputmsg" placeholder="" />
                        <div className="mb-20">
                            <label htmlFor="img" className="partner-input mb-0 d-flex justify-content-center align-items-center brandon mb-0 designupload-text">
                                <img className="designupload-img" src="/assets/design/upload.png" />
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                Upload Photo
                            </label>
                            <input type="file" id="img" name="img" accept="image/*" className="d-none" onChange={(e) => {
                                alert.show("Photo Uploaded")
                                imgcoverter(e)
                            }} />
                        </div>
                        {
                            imgarray.length ?
                                imgarray.map((item, ind) => {
                                    return (
                                        <div className="partner-input d-flex align-items-center" key={ind}>
                                            <div>
                                                <img src="/assets/design/fileicon.png" />
                                            </div>
                                            <label className="ml-3 mb-0 mt-1 imgitems brandon"> {item}</label>
                                            <div className="ml-auto mr-3" >

                                                <img className="cursor-pointer" src="/assets/design/delicon.png" onClick={(e) => removeImg(item)} />
                                            </div>
                                        </div>
                                    )
                                })
                                : ""
                        }
                        <div className="brandon mx-auto designart-note">(Please add a note to each upload mentioning the particular space, for example living room, dining area, bedroom etc.)</div>
                        <input type="submit" className="partner-submit text-uppercase" />
                    </form>
                </div>
                <Footer />
            </div>
        </div>
    )
}