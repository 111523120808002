import React, { useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/header/header";
// import "./Partnerapply.css";
import { useHistory } from "react-router"
import axios from "axios";
import { useAlert } from "react-alert";
import { base_url } from "../../helpers/baseURL";
import Loader from "../../components/checkout/loader";
import "./contact.css"

export const Contact = () => {
    const history = useHistory();
    const alert = useAlert();
    const [loader, setloader] = useState(false);
    const [imgdata, setimg] = useState("");


    const formsubmit = () => {

    }

    return (
        <div className="contactus-container">
            <div className="designart-container">
                <div className="partner-container">
                    <Header />

                    {loader ? <Loader /> : ""}
                    <div className="header-container">
                        <div className="selectBack pt-3 brandon d-flex align-self-left" onClick={() => history.goBack()}>
                            <img src="/assets/arrow-left.png" className="selectBackIcon" alt="thumb"></img>
                            <p className='ml-2'>Back</p>
                        </div>
                    </div>
                    <div className="container contactus-titlecontainer">
                        <div className="partner-title art-love text-center">
                            Contact Us
                        </div>
                        <div className="partner-subtitle partner-subtitle1 text-center brandon contact-sub mx-auto col-lg-8">
                            You can write to us if you have any questions about an order  or just about anything espravo. We’re always around.
                        </div>
                    </div>
                    <div className="d-lg-flex contactus-subcontainer justify-content-between">
                        <div className="order-2">
                            <div className="partner-title art-love text-left">
                                Write to us
                            </div>
                            <div className="partner-subtitle partner-subtitle1 text-left brandon contact-sub pt-0">
                                Fields marked with a * are compulsory
                            </div>
                            <form id="designart-form" className="d-flex flex-column partner-form mx-auto" onSubmit={(e) => { e.preventDefault(); formsubmit(e) }}>
                                <input name="First Name" id="fname" className="partner-input" placeholder="First Name*" />
                                <input name="Email" id="email" className="partner-input" placeholder="Email*" type={"email"} />
                                <input name="Phone Number" id="phone" className="partner-input" placeholder="Phone Number*" />
                                <input name="sub" id="sub" className="partner-input" placeholder="I need help with*" />

                                <textarea type={"text"} name="about" id="about" className="partner-input partner-inputmsg" placeholder="Message*" />

                                <input type="submit" className="partner-submit text-uppercase" />
                            </form>
                        </div>
                        <div className="order-1">
                            <div className="partner-title art-love text-left ">
                                Get in touch
                            </div>
                            <div className="partner-subtitle partner-subtitle1 text-left brandon contact-sub pt-0">
                                Call or email us with your questions.
                            </div>
                            <div className="d-flex contactus-details brandon align-items-center">
                                <img src="/assets/contact/calling.png" className="contact-calling" />
                                <a href="tel:04842700090" className="cursor-pointer mb-0">0484 270 0090</a>
                            </div>
                            <div className="d-flex contactus-details brandon align-items-center">
                                <img src="/assets/contact/mailing.png" className="contact-mailing" />
                                <a href="mailto:hello@espravo.com" className="cursor-pointer mb-0">hello@espravo.com</a>
                            </div>
                            <div className="d-flex contactus-details brandon align-items-center">
                                <img src="/assets/contact/locating.png" className="contact-locating" />
                                <a href="https://goo.gl/maps/FgqqnXHcwCAPTQ8y5" className="cursor-pointer mb-0">2/478-A, Panangad
                                    Kochi, Kerala 682506</a>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}