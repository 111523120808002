import React, { useEffect, useState, useContext } from "react"
import Footer from "../../components/Footer/footer"
import Header from "../../components/header/header"
import { Link } from "react-router-dom"
import { Context as ArtContext } from '../../context/ArtContext';
import { Context as ProductContext } from '../../context/ProductContext';
import { Context as UserContext } from '../../context/UserContext';
import { base_url } from '../../helpers/baseURL';
import HTMLReactParser from "html-react-parser";
import "./gallery.css"
import Loading from "react-js-loader";
import { MetaTags } from "react-meta-tags";
import { useAlert } from "react-alert";
import Loader from "../../components/checkout/loader";




const GallerySelect = () => {
    const { state, getGallery } = useContext(ArtContext);
    let PContext = useContext(ProductContext)
    let UContext = useContext(UserContext)
    const [loader, setloader] = useState(false)
    const [hover, setHover] = useState(null)
    const alert = useAlert()
    const galleries = [
        { "title": "The Fab Four", "description": "Black Gallery Frame" },
        { "title": "The Playful Eclectic", "description": "White Gallery Frame" },
        { "title": "The Memoir", "description": "Oakwood Tone Minimal Frame" },
        { "title": "The Fab Four", "description": "Black Gallery Frame" },
        { "title": "The Playful Eclectic", "description": "White Gallery Frame" },
        { "title": "The Memoir", "description": "Oakwood Tone Minimal Frame" },

    ]
    useEffect(() => {
        if (localStorage.getItem("userdat"))
            UContext.getWishlist()
        getGallery()
        window.scrollTo(0, 0)
    }, [])

    const checkClick = (e) => {
        if (e.target.className.includes("heart-symbol") || e.target.className.includes("heart-box") || e.target.className.includes("plus-symbol"))
            e.preventDefault()
    }

    const addWish = (id) => {
        setloader(true)
        PContext.addToWish(id)
            .then(res => {
                if (res === "item added") {
                    alert.show("Added to Wishlist", { type: "success" });
                    UContext.getWishlist()
                    setloader(false)
                }
            })
            .catch(err => {
                if (err === "logged_out") {
                    alert.show("Login to add to wishlist")
                }
                console.log('error on adding to wishlist', err)
                setloader(false)
            })
    }
    const deleteWish = (id) => {
        setloader(true)
        PContext.deleteFromWish(id)
            .then(res => {
                if (res === "item deleted") {
                    alert.show("Removed from Wishlist", { type: "success" });
                    UContext.getWishlist()
                    setloader(false)
                }
            })
            .catch(err => {
                setloader(false)
            })
    }
    return (
        <div className="gallery-wall">
            {loader ? <Loader /> : ""}
            <MetaTags>
                <title>Shop from the best selection of Gallery Walls Online at Espravo</title>
                <meta name="keywords" content="photo wall, gallery wall, photo gallery wall, gallery wall art, photo wall art, wall photo frame online" />
                <meta name="description" content="Add your personal style to any space by creating a gallery wall. Shop at Espravo for well-crafted gallery walls with the right mix of art prints & frames." />
                <link rel="canonical" href={`${window.location.origin}/gallery-walls/`} />
            </MetaTags>
            <Header />
            <div className="row mixed-media-banner">
                <div className="gift-heading col-sm-6 col-12 d-flex flex-column align-items-center justify-content-center">
                    <h1 className="main-heading mb-1 mt-sm-0 mt-3 text-center">
                        Gallery Walls
                    </h1>
                    <p className="brandon">Deck your walls with intriguing wall art in a dramatic gallery-wall style.</p>
                </div>
                <div className="col-sm-6 px-0 col-12">
                    <div className="mixed-media-banner-img">
                        <img src="/assets/gallerywall/banner.png" />
                    </div>
                </div>
            </div>
            <div className="header-container gallery-select pt-5 pb-5">
                {/* <div className="main-heading mb-1 mt-sm-0 mt-3 text-center">
                    Gallery Walls
                </div>
                <p className="brandon secondarycolor mb-5 text-center galSub">
                    Rendering your spaces with a touch of thoughtfulness and artistic allure, photo gallery {'\n'}
                    walls are a great way to showcase your treasured photos, art, prints, quotes and more.
                </p> */}
                <div className="frame-container mx-sm-5 px-sm-0">
                    <div className="frame-list gallery-list row mx-lg-4 mx-0">
                        {state.galleryWalls ? state.galleryWalls.data ? state.galleryWalls.data.map((gallery) => {
                            let path = gallery.attributes?.field_path_alias
                            let media = gallery.relationships.field_gallery_thumbnail.data ? state.galleryWalls.included.filter(item => item.id === gallery.relationships.field_gallery_thumbnail.data.id) : []
                            let variable = gallery.relationships?.default_variation?.data?.id
                            let listed = UContext.state.wishlist?.wishlist_variation?.find(x => x.relationships.purchasable_entity?.data?.id === variable)
                            return (
                                <Link to={`${path}/`} onMouseEnter={() => setHover(gallery.id)} onMouseLeave={() => setHover(null)} onClick={(e) => checkClick(e)} className="frame-ind col-6 py-md-5 py-3 col-md-8 col-sm-4 px-xl-5 px-lg-3 px-md-4 px-3 mb-4">
                                    {media.length ? <img className="pb-sm-3" alt="icon" src={media[0].attributes.image_style_uri.find(obj => obj.inner).inner} /> : ""}
                                    <div className="name-price mt-1">
                                        <p className="sub-heading GT-bold text-center">
                                            {gallery.attributes.title ? gallery.attributes.title : ""}
                                        </p>
                                        <p className="brandon text-center secondarycolor">
                                            <span>  {gallery.attributes.field_number_of_frame ? gallery.attributes.field_number_of_frame : ''}&nbsp;Frames | </span><span>{gallery.attributes.field_layout_size ? gallery.attributes.field_layout_size : ''}</span>
                                        </p>
                                        {listed ?
                                            <div className='heart-box listed' onClick={() => deleteWish(listed?.id)}>
                                                <img className="heart-symbol listed" src='/assets/gray-heart.png' />
                                            </div> :
                                            hover === gallery.id || window.innerWidth < 992 ?
                                                <div className='heart-box' onClick={() => addWish(variable)}>
                                                    <img className="heart-symbol" src='/assets/heart.png' />
                                                </div> : ""
                                        }
                                    </div>
                                </Link>
                            )
                        }
                        ) : "" : <div className='container py-5 my-5'> <Loading type="spinner-default" bgColor={"#808080"} title={"spinner-circle"} size={60} /></div>}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )

}
export default GallerySelect