import React, { useContext, useEffect, useState } from 'react';
import { Context as ArtContext } from '../../context/ArtContext';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import Header from '../../components/header/header';
import Footer from '../../components/Footer/footer';
import parse from 'html-react-parser';
import './blog.css'
import { base_url } from '../../helpers/baseURL';
import Stay from './stay';
import { MetaTags } from 'react-meta-tags';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const BlogDetails = () => {
    const query = useQuery()
    let history = useHistory();
    const { state, getArticleDetails, getArticleDetailsByNid } = useContext(ArtContext);
    let params = useParams();
    const [details, setDetails] = useState("")
    React.useEffect(() => {
        let nid = params.nid

        getArticleDetails(nid)
            .then(res => {

            })
            .catch(err => {
                if (query.get("nid"))
                    getArticleDetailsByNid(query.get("nid"))
                        .then(res => {
                            let path = res.attributes.field_path_alias
                            history.push(`${path}/`)
                        })
                        .catch(err => {
                            history.push("/404")
                        })
            })
    }, []);
    React.useEffect(() => {
        if (state.articledetails) {
            let details = state.articledetails.data[0] ? state.articledetails.data[0].attributes.body ? state.articledetails.data[0].attributes.body.value : "" : ""
            let imaged = details.replace(/src="\/sites/g, `src="${base_url}/sites`)
            setDetails(imaged)
            console.log("details", imaged)
        }
    }, [state]);

    const addClass = (content, type) => {
        const parser = new DOMParser();
        console.log("content is of", content)
        const doc = parser.parseFromString(content, 'text/html');

        // Select all heading elements and add a class
        const headings = doc.querySelectorAll('h1,h2,h3,h4,h5,h6,p');
        headings.forEach(heading => {
            if (type === "type-1" || type === "type-2" || type === "type-5" || type === "type-6") {
                heading.classList.add('blog-sub-heading', "text-center", "GT-bold");
            }
            else if (type === "type-4") {
                heading.classList.add("ml-2", "ml-sm-3", "blog-sub-heading", "text-center", "GT-bold");
            }
            else if (type === "type-3") {
                heading.classList.add("mr-2", "mr-sm-3", "blog-sub-heading", "text-center", "GT-bold", "ml-0")
            }
        });

        // Convert the Document back to a string
        const modifiedContent = new XMLSerializer().serializeToString(doc);
        console.log("content final", doc.body.innerHTML)
        return doc.body.innerHTML;
    }
    return (
        <div className='blog-details-page'>
            <Header />
            <div className='container'>
                {state.articledetails ? state.articledetails.data[0] ? state.articledetails.data[0].attributes.metatag_normalized ? state.articledetails.data[0].attributes.metatag_normalized.length ?
                    <MetaTags>
                        {state.articledetails.data[0].attributes.metatag_normalized.map((item) => {
                            if (item.tag === "meta")
                                if (item.attributes.name === "title")
                                    return (
                                        <title>{item.attributes.content}</title>
                                    )
                                else
                                    return <meta name={`${item.attributes.name}`} content={`${item.attributes.content}`} />
                        })}
                        <link rel="canonical" href={`${window.location.origin}${state.articledetails.data[0].attributes.field_path_alias}/`} />
                    </MetaTags>
                    : "" : '' : "" : ''
                }
                <div className=' pt-3 pb-5'>
                    <Link className="mb-1 selectBack brandon d-flex align-items-center" to={`/blog/${localStorage.getItem("blog_back") ? localStorage.getItem("blog_back") != "1" ? `${localStorage.getItem("blog_back")}/` : "" : ""}`}>
                        <img src="/assets/arrow-left.png" className="selectBackIcon" alt="thumb"></img>
                        <p className='ml-2'>Back</p>
                    </Link>
                </div>
                {state.articledetails ? state.articledetails.data[0].relationships.field_image.data ?
                    <div className="blog-main-image blog-img">
                        <img src={base_url + state.articledetails.included.filter(item => item.id === state.articledetails.data[0].relationships.field_image.data.id)[0].attributes.uri.url} alt="blog" />
                    </div>
                    : '' : ""}
            </div>
            <div id='blogdetail' className="mb-sm-5">
                {state.articledetails ?
                    <div className="single-blog-container">
                        <h1 className="main-heading mb-1 mt-sm-0 text-center">{state.articledetails.data[0].attributes.title}</h1>
                        <div className='row'>
                            <p className="col-12 blog-main-description mb-0 mx-auto text-center brandon mx-0">{state.articledetails.data[0].attributes.body ? parse(details) : ""}</p>
                        </div>
                        {state.articledetails.data[0] ?
                            <div className=''>
                                {state.articledetails.data[0].relationships.field_content_blocks.data.length ? state.articledetails.data[0].relationships.field_content_blocks.data.map((a, ind) => {
                                    let data = state.articledetails.included.filter(x => x.id === a.id)
                                    console.log("paragraph included", data)
                                    if (a.type == "paragraph--type_3")
                                        return (
                                            <div className='row blog-type-container type-3'>
                                                <div className='col-6 type-text'>
                                                    {data.length ? data[0].attributes.field_title ? parse(addClass(data[0].attributes.field_title.value, "type-3")) : "" : ""}
                                                    <p className='mr-2 mr-sm-3 brandon blog-type-details text-center'>{data.length ? data[0].attributes.field_long_text ? parse(data[0].attributes.field_long_text.value) : "" : ""}</p>
                                                </div>
                                                <div className='col-6'>
                                                    {data[0].relationships.field_image.data ?
                                                        <div className='type-3-imageContainer'>
                                                            <img className='' src={base_url + state.articledetails.included.filter(x => x.id === data[0].relationships.field_image.data.id)[0].attributes.uri.url} />
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>
                                        )
                                    else if (a.type == "paragraph--type_4")
                                        return (
                                            <div className='row blog-type-container type-4'>
                                                <div className='col-6'>
                                                    {data[0].relationships.field_image.data ?
                                                        <div className='type-3-imageContainer'>
                                                            <img className='' src={base_url + state.articledetails.included.filter(x => x.id === data[0].relationships.field_image.data.id)[0].attributes.uri.url} />
                                                        </div>
                                                        : ""}
                                                </div>
                                                <div className='col-6 type-text'>
                                                    {data.length ? data[0].attributes.field_title ? parse(addClass(data[0].attributes.field_title.value, "type-4")) : "" : ""}
                                                    <p className="ml-2 ml-sm-3 brandon blog-type-details text-center">{data.length ? data[0].attributes.field_long_text ? parse(data[0].attributes.field_long_text.value) : "" : ""}</p>
                                                </div>
                                            </div>
                                        )
                                    else if (a.type == "paragraph--type_5")
                                        return (
                                            <div className='blog-type-container type-5'>
                                                <div className='col-12 mt-sm-5 mt-3'>
                                                    {data[0].relationships.field_image.data ?
                                                        <div className='type-2-image-container mb-sm-4 mb-2'>
                                                            <img className='mx-auto' src={base_url + state.articledetails.included.filter(x => x.id === data[0].relationships.field_image.data.id)[0].attributes.uri.url} />
                                                        </div>
                                                        : ""}
                                                </div>
                                                <div className='col-12 mt-3'>
                                                    <div className='mx-auto'>
                                                        {data.length ? data[0].attributes.field_title ? parse(addClass(data[0].attributes.field_title.value, "type-5")) : "" : ""}
                                                        <p className="brandon blog-type-details text-center">{data.length ? data[0].attributes.field_long_text ? parse(data[0].attributes.field_long_text.value) : "" : ""}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    else if (a.type == "paragraph--type_2")
                                        return (
                                            <div className='blog-type-container type-2'>
                                                <div className='col-12'>
                                                    <div className='mx-auto'>
                                                        {data.length ? data[0].attributes.field_title ? parse(addClass(data[0].attributes.field_title?.value, "type-2")) : "" : ""}
                                                        <p className="brandon blog-type-details text-center">{data.length ? data[0].attributes.field_long_text ? parse(data[0].attributes.field_long_text.value) : "" : ""}</p>
                                                    </div>
                                                </div>
                                                <div className='col-12 mt-sm-5 mt-3'>
                                                    {data[0].relationships.field_image.data ?
                                                        <div className='type-2-image-container'>
                                                            <img className='mx-auto' src={base_url + state.articledetails.included.filter(x => x.id === data[0].relationships.field_image.data.id)[0].attributes.uri.url} />
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>
                                        )
                                    else if (a.type == "paragraph--type1")
                                        return (
                                            <div className='row blog-type-container type-1'>
                                                <div className='col-6 section-1'>
                                                    <div className=''>
                                                        {data.length ? data[0].attributes.field_title ? parse(addClass(data[0].attributes.field_title.value, "type-1")) : "" : ""}
                                                        <p className="brandon blog-type-details text-center">{data.length ? data[0].attributes.field_long_text ? parse(data[0].attributes.field_long_text.value) : "" : ""}</p>
                                                    </div>
                                                    <div className='mt-3'>
                                                        {data[0].relationships.field_image.data ?
                                                            <div className='type-1-imageContainer'>
                                                                <img className='w-100 mx-auto' src={base_url + state.articledetails.included.filter(x => x.id === data[0].relationships.field_image.data.id)[0].attributes.uri.url} />
                                                            </div>
                                                            : ""}
                                                    </div>
                                                </div>
                                                <div className='col-6 section-2'>
                                                    <div className=''>
                                                        {data.length ? data[0].attributes.field_title_2 ? parse(addClass(data[0].attributes.field_title_2.value, "type-1")) : "" : ""}
                                                        <p className="brandon blog-type-details text-center">{data.length ? data[0].attributes.field_long_text_2 ? parse(data[0].attributes.field_long_text_2.value) : "" : ""}</p>
                                                    </div>
                                                    <div className='mt-3'>
                                                        {data[0].relationships.field_image_2.data ?
                                                            <div className='type-1-imageContainer'>
                                                                <img className='w-100 mx-auto' src={base_url + state.articledetails.included.filter(x => x.id === data[0].relationships.field_image_2.data.id)[0].attributes.uri.url} />
                                                            </div>
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    else if (a.type == "paragraph--type_6")
                                        return (
                                            <div className='row blog-type-container type-6'>
                                                <div className='col-6 section-1'>
                                                    <div className='mb-sm-4 mb-2'>
                                                        {data[0].relationships.field_image.data ?
                                                            <div className='type-1-imageContainer'>
                                                                <img className='w-100 mx-auto' src={base_url + state.articledetails.included.filter(x => x.id === data[0].relationships.field_image.data.id)[0].attributes.uri.url} />
                                                            </div>
                                                            : ""}
                                                    </div>
                                                    <div className=''>
                                                        {data.length ? data[0].attributes.field_title ? parse(addClass(data[0].attributes.field_title.value, "type-6")) : "" : ""}
                                                        <p className="brandon blog-type-details text-center">{data.length ? data[0].attributes.field_long_text ? parse(data[0].attributes.field_long_text.value) : "" : ""}</p>
                                                    </div>
                                                </div>
                                                <div className='col-6 section-2'>
                                                    <div className='mb-sm-4 mb-2'>
                                                        {data[0].relationships.field_image_2.data ?
                                                            <div className='type-1-imageContainer'>
                                                                <img className='w-100 mx-auto' src={base_url + state.articledetails.included.filter(x => x.id === data[0].relationships.field_image_2.data.id)[0].attributes.uri.url} />
                                                            </div>
                                                            : ""}
                                                    </div>
                                                    <div className=''>
                                                        {data.length ? data[0].attributes.field_title_2 ? parse(addClass(data[0].attributes.field_title_2.value, "type-6")) : "" : ""}
                                                        <p className="brandon blog-type-details text-center">{data.length ? data[0].attributes.field_long_text_2 ? parse(data[0].attributes.field_long_text_2.value) : "" : ""}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                })
                                    : ""}
                            </div>
                            : ''}
                    </div>
                    : ''}
            </div>
            {/* <div className='bg-white stay-page-component'>
                <Stay />
            </div> */}
            <Footer />
        </div >
    );
}

export default BlogDetails;