import React, { useContext, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { TokenCheck } from '../../network/tokenChecker';
import { Context as AppLoadContext } from '../../context/AppLoadContext';
import fbm from '../../assets/Facebook.png'
import insta_m from '../../assets/Instagram.png'
import ytbm from '../../assets/ytb.png'
import pinterest_m from '../../assets/pinterest_m.png'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Logo from '../../assets/Home/Flogo.png'
import fb from '../../assets/fb.png'
import insta from '../../assets/insta.png'
import utube from '../../assets/Youtube.png'
import pinterest from '../../assets/pinterest.png'
import './footer.css'
import axios from 'axios';
import { base_url } from '../../helpers/baseURL';
import { ValidateEmail } from '../../helpers/misc_func'
import { useAlert } from 'react-alert';
import Loader from '../checkout/loader';

const Footer = () => {
    // const { state, login_state, log_out } = useContext(AppLoadContext);
    // const alert = useAlert()
    // const logout = () => {
    //     log_out()
    //         .then(res => {
    //             alert.show('Logged Out', {
    //                 timeout: 2000, // custom timeout just for this one alert
    //                 type: 'info'
    //             })
    //         })
    // }
    const [email, setEmail] = useState("")
    const [loader, setloader] = useState(false)
    const alert = useAlert()
    React.useEffect(() => {

    }, []);
    const signupnews = () => {
        if (ValidateEmail) {
            setloader(true)
            axios.get(`${base_url}/jsonapi/node/news_letter?filter[title]=${email}`)
                .then(res => {
                    console.log("found value", res.data.data)
                    if (res.data.data.length > 0) {
                        setloader(false)
                        alert.show("This email is already in our subscribers list. Thank You.")
                    }
                    else {
                        axios({
                            method: 'post',
                            headers: {
                                'Accept': `application/vnd.api+json`,
                                "Content-type": "application/vnd.api+json",
                            },
                            url: `${base_url}/jsonapi/node/news_letter`,
                            data: {
                                "data": {
                                    "type": "node--news_letter",
                                    "attributes": {
                                        "title": email
                                    }
                                }
                            }
                        }).then(response => {
                            setloader(false)
                            alert.show("Thank you for subscribing to our Newsletter.")
                            setEmail("")
                        })
                            .catch(err => {
                                console.log("email check error", err.message)
                                setloader(false)
                            })
                    }
                })
                .catch(err => {
                    console.log("email check error", err.message)
                    setloader(false)
                })
        }
        else {
            alert.show("Enter valid email")
        }
    }
    return (
        // <AppLoadContext>
        <div id="footer" className='footer-2'>
            {loader ? <Loader /> : ''}
            {/* MOBILE ACCORDION FOOTER */}
            <div id="mob_footer">
                <Accordion allowZeroExpanded >
                    <AccordionItem className='illusion'>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Is free will real or just an illusion?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                In ad velit in ex nostrud dolore cupidatat consectetur
                                ea in ut nostrud velit in irure cillum tempor laboris
                                sed adipisicing eu esse duis nulla non.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>

                </Accordion>

            </div>
            <div className='mobile-footer'>
                <Accordion allowZeroExpanded>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <div className='accord-title'>Shop</div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="body_links">
                                <Link to="/art/" onClick={() => { localStorage.setItem("artmode", "shopart"); localStorage.setItem("flowtype", "shopart"); sessionStorage.setItem("artFilters", `[]`) }}>
                                    Art
                                </Link>
                                <Link to="/custom-framing/" onClick={() => { localStorage.setItem("artmode", "frame"); localStorage.setItem("flowtype", "framing") }}>
                                    Framing
                                </Link>
                                <Link to="/gallery-walls/">
                                    Gallery Walls
                                </Link>
                                <Link to="/mixed-media/">
                                    Mixed Media Art
                                </Link>
                                <Link to="/decor-objects/">
                                    Decor Objects
                                </Link>
                                <Link to="/gifts/">
                                    Gifts
                                </Link>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <div className='accord-title'> About</div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="body_links">
                                <Link to="/about/">
                                    Company
                                </Link>
                                <Link to="/faq/">
                                    FAQ
                                </Link>
                                <Link to="/contact/">
                                    Contact
                                </Link>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <div className='accord-title'> Resources</div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="body_links">
                                <Link to="/blog/">
                                    Blog
                                </Link>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    {/* <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <div className='accord-title'>Help</div>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                    </AccordionItem> */}
                </Accordion>
                <div className="container mob-signup my-4">
                    <div className="footer-head brandon mb-2">Sign up for our Newsletter</div>
                    <div class="lemlist-field-group">
                        {/* <label for="lemlist-email">Email Address</label> */}
                        <input type="email" className="mb-2" value={email} onChange={(e) => { setEmail(e.target.value) }} name="email" id="lemlist-email" required />
                    </div>
                    {/* <div class="lemlist-field-group">
                            <label for="lemlist-firstName">First Name</label>
                            <input type="text" value="" name="firstName" id="lemlist-firstName"/>
                        </div>
                        <div class="lemlist-field-group">
                            <label for="lemlist-lastName">Last Name</label>
                            <input type="text" value="" name="lastName" id="lemlist-lastName"/>
                        </div> */}
                    <div class="lemlist-clear">
                        <input type="submit" value="SUBSCRIBE" onClick={() => signupnews()} name="subscribe" className='black-btn bgsecondary whitecolor brandon' id="lemlist-embed-sign-up" />
                    </div>
                    {/* <div className="mb-2">
                        <input placeholder="Email" className="" />
                    </div>
                    <div className="black-btn bgsecondary whitecolor brandon">SUBSCRIBE</div> */}
                </div>
                <div className='social-mobile'>
                    <h3 className='follow'>FOLLOW US</h3>
                    <div className='social-icon-mobile'>
                        <a target='_blank' href='https://instagram.com/espravo?igshid=MDg3NTkzMDk='><img alt="icon" src={insta_m} className='socail-icon-img' /></a>
                        <a target='_blank' href='https://www.facebook.com/espravo/'><img alt="icon" src={fbm} className='socail-icon-img' /></a>
                        <a target='_blank' href='https://www.youtube.com/channel/UCuUO1MTQ3y6MHE7WsPiNHZQ'><img alt="icon" src={ytbm} className='socail-icon-img' /></a>
                        <a target='_blank' href='https://in.pinterest.com/espravo/'><img alt="icon" src={pinterest_m} className='socail-icon-img' /></a>
                    </div>
                    <div className='d-flex text-white brandon w-100 justify-content-center other-mob-links'>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/terms-of-service">Terms of Service</Link>
                    </div>
                </div>
            </div>
            <div id="desk-footer" className="desk-footer px-0 bggrey">
                <div className="header-container px-0">
                    <div className="row px-0">
                        <div className="col-sm-3 pl-0 d-flex flex-column justify-content-between">
                            <img src="/assets/Flogo.png" className="footer-logo" />
                        </div>
                        <div className="col-sm-9 px-0">
                            <div className="d-flex justify-content-between">
                                <div className='col-lg-7 col-8 pl-0 row'>
                                    <div className="footer-menu-list col-sm-6 pr-0">
                                        <div className="footer-head brandon mb-3">Shop</div>
                                        <div className='row'>
                                            <div className='col-6 pl-0 footer-menu-sections'>
                                                <Link className='footer-subhead mb-2 brandon' to="/art/" onClick={() => { localStorage.setItem("artmode", "shopart"); localStorage.setItem("flowtype", "shopart"); sessionStorage.setItem("artFilters", `[]`) }}>
                                                    Art Prints
                                                </Link>
                                                <Link className='footer-subhead mb-2 brandon' to="/mixed-media/">
                                                    Mixed Media Art
                                                </Link>
                                                <Link className='footer-subhead mb-2 brandon' to="/decor-objects/">
                                                    Decor Objects
                                                </Link>

                                            </div>
                                            <div className='col-6 pl-0 footer-menu-sections'>
                                                <Link className='footer-subhead mb-2 brandon' to="/gallery-walls/">
                                                    Gallery Walls
                                                </Link>
                                                <Link className='footer-subhead mb-2 brandon' to="/custom-framing/" onClick={() => { localStorage.setItem("artmode", "frame"); localStorage.setItem("flowtype", "framing") }}>
                                                    Custom Framing
                                                </Link>
                                                <Link className='footer-subhead mb-2 brandon' to="/gifts/">
                                                    Gifts
                                                </Link>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="footer-menu-list col-sm-3">
                                        <div className="footer-head brandon mb-3">About</div>
                                        <Link className='footer-subhead mb-2 brandon' to="/about/">
                                            Company
                                        </Link>
                                        <Link className='footer-subhead mb-2 brandon' to="/faq/">
                                            FAQ
                                        </Link>
                                        <Link className='footer-subhead mb-2 brandon' to="/contact/">
                                            Contact
                                        </Link>
                                    </div>
                                    <div className="footer-menu-list col-sm-3">
                                        <div className="footer-head brandon mb-3">Resources</div>
                                        <Link className='footer-subhead mb-2 brandon' to="/blog/">
                                            Blog
                                        </Link>
                                    </div>
                                </div>
                                <div className="footer-social-cont pr-0 col-lg-5 col-4 justify-content-end">
                                    <div className="footer-head brandon mb-2">Sign up for our Newsletter</div>
                                    <div class="lemlist-field-group">
                                        {/* <label for="lemlist-email">Email Address</label> */}
                                        <input type="email" placeholder="Email" className="mb-2" value={email} onChange={(e) => { setEmail(e.target.value) }} name="email" id="lemlist-email" required />
                                    </div>
                                    <div class="lemlist-clear">
                                        <input type="submit" value="SUBSCRIBE" onClick={() => signupnews()} name="SUBSCRIBE" placeholder='SUBSCRIBE' className='black-btn bgsecondary whitecolor brandon' id="lemlist-embed-sign-up" />
                                    </div>
                                    <div className="footer-menu-list">
                                        <div className="social-icons d-flex mt-3">
                                            <a href='https://instagram.com/espravo?igshid=MDg3NTkzMDk=' target='_blank'><img alt="icon" src={insta} /></a>
                                            <a href='https://www.facebook.com/espravo/' target='_blank'><img alt="icon" src={fb} /></a>
                                            <a href='https://www.youtube.com/channel/UCuUO1MTQ3y6MHE7WsPiNHZQ' target='_blank'><img alt="icon" src={utube} /></a>
                                            <a href='https://in.pinterest.com/espravo/' target='_blank'><img alt="icon" src={pinterest} /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-bottom col-lg-7 col-8 pl-0 d-flex mt-1">
                                <p className="d-flex brandon col-xl-3 col-4 p-0 copy-right">
                                    © 2024 Espravo
                                </p>
                                <Link className="d-flex col-xl-3 col-4 pl-0 terms-privacy termsOfService-text brandon" to="/terms-of-service/">
                                    Terms of Service
                                </Link>
                                <Link className="d-flex col-xl-3 col-4 pl-0 terms-privacy termsOfService-text brandon" to="/privacy-policy/">
                                    Privacy Policy
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // </AppLoadContext>
    );
}

export default Footer;