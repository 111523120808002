import React, { useContext, useState, useEffect, useRef } from 'react'
import Loader from '../../components/checkout/loader'
import axios from 'axios'
import { base_url } from '../../helpers/baseURL'
import './otplogin.css'
import eyeopen from '../../assets/eyeopen.png'
import tick from '../../assets/Vector.png'
import { Context as ArtContext } from '../../context/ArtContext'
import { ValidateEmail } from '../../helpers/misc_func'
import { useHistory } from 'react-router'
import OtpInput from 'react-otp-input'
import { useAlert } from 'react-alert'
import Timer from './timer'
import { Link } from 'react-router-dom'

const OTPLogin = props => {
  const {
    state,
    login_signup,
    setLoginStatus,
    reset_pwd,
    verify,
    getOTP,
    getToken
  } = useContext(ArtContext)
  const [remember, setremember] = useState(false)
  const [login, setLogin] = useState(true)
  const [send_pwd, set_send] = useState(false)
  const [email, setemail] = useState('')
  const [forgot, setforgot] = useState(false)
  const [code, setCode] = useState('+91')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [altphone, setAltPhone] = useState('')
  const [registermail, setRegisterMail] = useState('')
  const [loader, setloader] = useState(false)
  const [togglepass, settogglepass] = useState(false)
  const [password, setpass] = useState('')
  const [otpwidnow, setOTPWindow] = useState(false)
  const [otp, setOtp] = useState('')
  const [confirmpass, setconfirmpass] = useState('')
  const history = useHistory()
  const alert = useAlert()
  const [trigger, setTrigger] = useState(false)
  const [expired, setExpiry] = useState(false)

  useEffect(() => {
    setemail('')
  }, [login])
  useEffect(() => {
    if (otpwidnow) {
      let tag = document.getElementsByClassName("otp-input-tag")
      console.log("classes ", tag.item(0))
      if (tag)
        tag.item(0).focus()
    }
  }, [otpwidnow])
  const handleKey = event => {
    if (event.keyCode == 13) {
      loginSubmit()
    }
  }
  useEffect(() => {
    if (otp.length === 4) {
      otp_login()
    }
  }, [otp])
  const changeLogin = () => {
    console.log('login change is triggering', window.location.pathname)
    if (window.location.pathname == '/signup') history.push('/login')
    else if (window.location.pathname == '/login') {
      history.push('/signup')
    }
  }
  const checkVerification = () => {
    const data = {
      mail: email
    }
    axios({
      method: 'post',
      url: base_url + '/email_verifications/account_check',
      data: data
    }).then(response => {
      if (response.data.data.account_status) {
        if (response.data.data.account_status === '1')
          alert.show('The email is already taken')
        else {
          alert.show('The email is already taken,Email verification is pending')
          setOTPWindow(true)
        }
      }
    })
  }
  const loginSubmit = () => {
    if (email && password) {
      setloader(true)
      if (ValidateEmail(email)) {
        login_signup(login, email, password, confirmpass)
          .then(res => {
            setloader(false)
            if (res === 'Otp') {
              alert.show('Otp has been sent to your mail')
              setOTPWindow(true)
            } else {
              alert.show('Logged In')
              console.log('href in login', window.location.href)
              if (window.location.href.includes('redirect=checkout'))
                window.location.href = '/checkout/shipping/'
              else if (window.location.href.includes('/login')) {
                window.location.href = '/'
              }
              if (window.location.href.includes('/checkout/details')) {
                window.location.href = '/checkout/shipping/'
              } else {
                if (window.innerWidth > 565) props.closeLogin(false)
              }
            }
          })
          .catch(err => {
            setloader(false)
            console.log('error', err)
            if (err === 'exist') {
              alert.show(`The username ${email} is already taken`)
            } else if (err === 'invalid_credentials') {
              alert.show('Email or Password is incorrect')
            } else if (err === 'user error') {
              checkVerification()
            } else if (err === "password doesn't match.")
              alert.show(`Password and confirm password do not match.`)
            else if (err == 'token error') {
              alert.show('You need to verify email')
              setOTPWindow(true)
            } else {
              alert.show('Try Later!')
            }
          })
      } else {
        setloader(false)
        alert.show('Invalid Email')
      }
    } else {
      if (email === '') {
        alert.show('Enter an email')
      }
      if (password === '') {
        alert.show('Enter a password')
      }
    }
  }
  const setUserType = detail =>
    new Promise((res, rej) => {
      if (ValidateEmail(detail)) {
        res('mail')
      }
      else {
        var txtPhone = detail
        var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
        var mobileno = /^((\\+91-?)|0)?[0-9]{10}$/
        if (!txtPhone.match(phoneno) || !txtPhone.match(mobileno))
          rej('invalid')
        else res('mobile')
      }
      //below rej added for just current phone number sms block line can be deleted after sms issue fixed and above else section can be uncommented
    })
  useEffect(() => {
    if (expired) setTrigger(false)
  }, [expired])
  const checkUserType = (details, operation) => {
    console.log('checking data', details)
    setloader(true)
    setUserType(details)
      .then(type => {
        console.log('creating: ', type, ' user')
        getOTP(details, type)
          .then(res => {
            if (operation === 'resend') {
              setTrigger(true)
              setExpiry(false)
              alert.show(`A new OTP is sent to - ${details}`, {
                type: 'success'
              })

            }
            console.log('response in otp', JSON.parse(res))
            localStorage.setItem('email', JSON.parse(res).mobile)
            localStorage.setItem('otp_initiated', details)
            setloader(false)
            setOTPWindow(true)
          })
          .catch(err => {
            console.log('error on get otp', err)
            if (err === 'no user found') {
              if (login) {
                alert.show(`User ${email} not registered`)
                setLogin(false)
                if (type === 'mobile') setPhone(email)
                else setRegisterMail(email)
                setloader(false)
              } else {
                login_signup(false, email, '123', '123', 'no-email')
                  .then(res => {
                    getOTP(details, type).then(res => {
                      console.log('response in otp', JSON.parse(res))
                      setloader(false)
                      setOTPWindow(true)
                    })
                  })
                  .catch(err => {
                    console.log('error on otp signup', err)
                    alert.show('Something went wrong')
                  })
              }
            } else {
              setloader(false)
              alert.show('Invalid Entry')
            }
          })
      })
      .catch(err => {
        console.log('error in check user type', err)
        setloader(false)
        // alert.show('Invalid Entry')
        //below alert added for just current phone number sms block ,line can be deleted after sms issue fixed and above alert can be uncommented
        alert.show("Enter a valid email")
      })
  }
  const otp_login = (e) => {
    if (e)
      e.preventDefault();
    console.log("calling otplogin")
    if (otp.length < 4) {
      alert.show('Enter Complete OTP')
    } else {
      setloader(true)
      getToken(localStorage.getItem('email'), otp)
        .then(res => {
          setloader(false)
          console.log('response in signup', res)
          setLoginStatus(true)
          alert.show('Logged In', {
            type: 'success',
            timeout: 3000,
            onClose: () => {
              if (history.location.pathname === '/checkout/details')
                history.push('/checkout/shipping/')
              else window.location.href = `${history.location.pathname}`
            }
          })
        })
        .catch(err => {
          setloader(false)
          console.log('creation error', err)
          if (err === 'invalid_credentials') {
            alert.show(`Incorrect OTP`, { type: 'error' })
          } else {
            alert.show('Try Later!')
          }
        })
    }
  }
  const Register = () => {
    if (registermail && name && phone) {
      setloader(true)
      login_signup(false, phone, '123', '123', name, registermail, altphone)
        .then(res => {
          getToken(phone, '123')
            .then(res => {
              setloader(false)
              console.log('response in signup', res)
              alert.show('Logged In', { type: 'success' })
              if (history.location.pathname === '/checkout/details')
                history.push('/checkout/shipping/')
              else window.location.reload()
            })
            .catch(err => {
              setloader(false)
              console.log('creation error', err)
              if (err === 'exist') {
                alert.show(`The username ${email} is already taken`)
              } else if (err === 'user error')
                alert.show(`The username ${email} is already taken`)
              else {
                alert.show('Try Later!')
              }
            })
        })
        .catch(err => {
          setloader(false)
          console.log('error on otp signup', err)
          if (err === 'mail and user error')
            alert.show('The Email and Mobile Number are already taken')
          else if (err === 'mail error')
            alert.show('The Email is already taken')
          else if (err === 'user error')
            alert.show('The Mobile Number is already taken')
          else alert.show('Something went wrong')
        })
    } else {
      alert.show('Enter all details')
    }
  }

  const time = new Date()
  time.setSeconds(time.getSeconds() + 60)
  return (
    <div id='login-signup'>
      <div className='login awrContainer otp_login'>
        {loader ? <Loader /> : ''}
        {otpwidnow ? (
          <div>
            <div className='login-heading GT-bold text-center'>
              OTP Verification
            </div>
            <p className='brandon text-center'>
              Enter the OTP sent to - {localStorage.getItem('otp_initiated')}
            </p>
            <form
              onSubmit={(e) => { otp_login(e) }}
            >
              <OtpInput
                containerStyle='otp-input-container'
                className='otp-input'
                value={otp}
                onChange={e => setOtp(e)}
                numInputs={4}
                inputStyle='otp-input-tag brandon'
                separator={''}
                renderInput={(props) => <input {...props} />}
                isInputNum
              />
              <Timer
                trigger={trigger}
                fireExpire={setExpiry}
                expiryTimestamp={time}
              />
              {/* <input className="profile-input profile-inputAwr  font-400 brandon" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder="Enter OTP" /> */}
              <button
                onClick={(e) => otp_login(e)}
                className={
                  'otp-submit-button brandon text-center px-auto d-flex w-100 justify-content-ceter align-items-center mx-auto'
                }
              >
                Submit
              </button>
            </form>
            <p
              className={`brandon text-center ${expired ? 'pe-auto' : 'pe-none'
                }`}
            >
              Don’t receive code?{' '}
              <strong
                onClick={() =>
                  checkUserType(localStorage.getItem('otp_initiated'), 'resend')
                }
              >
                Re-send
              </strong>
            </p>
          </div>
        ) : (
          <div
            className={`login-or-sign-up w-100 ${login ? 'login-pop' : 'signup-pop'
              }`}
          >
            <div className='login-heading GT-bold black-text text-left'>
              {login ? 'Login or Signup' : 'Enter Your Details'}
            </div>
            <form
              autoComplete={!login ? 'off' : ''}
              onSubmit={login ? (e) => { checkUserType(email); e.preventDefault(); } : (e) => { Register(); e.preventDefault(); }}
            >
              <div className='inp-fields'>
                {
                  login ? (
                    <div className='login-fields w-100'>
                      <label className='login-label brandon'>
                        Mobile Number / 
                        Email
                      </label>
                      <input
                        className='profile-input profile-inputAwr w-100  font-400 brandon'
                        value={email}
                        onChange={e => setemail(e.target.value)}
                        placeholder='Enter Mobile Number or Email'
                        // placeholder='Enter Email'
                      />
                    </div>
                  ) : (
                    <div className='register-fields w-100'>
                      <label className='login-label brandon'>
                        Mobile Number
                      </label>
                      <input
                        type='text'
                        className='profile-input profile-inputAwr w-100  font-400 brandon'
                        placeholder='Mobile Number'
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                      ></input>

                      <label className='login-label brandon'>Name</label>
                      <input
                        type='text'
                        className='profile-input profile-inputAwr w-100  font-400 brandon'
                        placeholder='Name'
                        value={name}
                        onChange={e => setName(e.target.value)}
                      ></input>

                      <label className='login-label brandon'>Email</label>
                      <input
                        type='text'
                        className='profile-input profile-inputAwr w-100  font-400 brandon'
                        placeholder='Email'
                        value={registermail}
                        onChange={e => setRegisterMail(e.target.value)}
                      ></input>

                      <label className='login-label brandon'>
                        Alternative Number (Optional)
                      </label>
                      <input
                        type='text'
                        className='profile-input profile-inputAwr w-100  font-400 brandon'
                        placeholder='Alternative Number'
                        value={altphone}
                        onChange={e => setAltPhone(e.target.value)}
                      ></input>
                    </div>
                  )
                  // <div className="d-flex mobile-input g-2">
                  //     <input className="profile-select" maxLength={5} minLength={2} onChange={(e) => setCode(e.target.value)} value={code} />
                  //     <input className="profile-input profile-inputAwr  font-400 brandon" value={email} onChange={(e) => setemail(e.target.value)} placeholder="Mobile Number" />
                  // </div>
                }
                {login ? (
                  <p className='brandon login-label font-14 text-center mb-sm-4 mb-2'>
                    By continuing, I agree to the{' '}
                    <Link to="/terms-of-service/" className=''>
                      Terms of Use
                    </Link>{' '}
                    &{' '}
                    <Link to="/privacy-policy/" className=''>
                      Privacy Policy
                    </Link>
                  </p>
                ) : (
                  ''
                )}
              </div>
              <input
                type='button'
                value={'Continue'}
                onClick={() => (login ? checkUserType(email) : Register())}
                className={login ? 'black-btn brandon' : 'black-btn brandon'}
              ></input>
            </form>
            {/* <div className='remember-container justify-content-center bottom-link mt-40px '>
                            <p onClick={() => { setLogin(!login); changeLogin() }} className="brandon cursor font-500  login-page-change desk-only">
                                {login ? <label className='mb-0 cursor'>Login or Signup</label> : <label className='ml cursor mb-0'>Login in using Email</label>}
                            </p>
                            <p onClick={() => { setLogin(!login); changeLogin() }} className="brandon cursor font-500 login-page-change mobile-only">
                                {login ? "Register Now" : "Back to sign in"}
                            </p>
                        </div> */}
          </div>
        )}
      </div>
    </div>
  )
}
export default OTPLogin
