import createDataContext from './createDataContext';
import axios from 'axios'
import { base_url } from '../helpers/baseURL';
import { createBrowserHistory, createHashHistory } from 'history';

const backendReducer = (state = {}, action) => {
    switch (action.type) {
        case 'login':
            return {
                ...state, loginDetails: action.payload
            };
        case 'offset':
            return {
                ...state, offset: action.payload
            };
        case 'homeblock4':
            return {
                ...state, homeBlock4: action.payload
            };
        case 'homeblock1':
            return {
                ...state, homeBlock1: action.payload
            };
        case 'homeblock2':
            return {
                ...state, homeBlock2: action.payload
            };
        case 'homeblock3':
            return {
                ...state, homeBlock3: action.payload
            };
        case 'login_signup':
            return {
                ...state, signups: action.payload
            };
        case 'framedream':
            return {
                ...state, frameDream: action.payload
            };
        case 'testimonial':
            return {
                ...state, testimonial: action.payload
            };
        case 'order_history':
            return {
                ...state, orders: action.payload
            };
        case 'single_order':
            return {
                ...state, singleorder: action.payload
            };
        case 'testlen':
            return {
                ...state, length: action.payload
            };
        case 'slick':
            return {
                ...state, slick: action.payload
            };
        case 'wishlist':
            return {
                ...state, wishlist: action.payload
            };
        default:
            return state;
    }
};
let backendData = { client_id: "8d52aaaa-e680-43b8-8fbe-34b3a01520bb", client_secret: "user" }
const getToken = (email, password) => new Promise((resolve, reject) => {
    let data = new FormData()
    data.append('grant_type', 'password')
    data.append('client_id', backendData.client_id)
    data.append('client_secret', backendData.client_secret)
    data.append('username', email)
    data.append('password', password)
    axios({
        method: 'post',
        url: base_url + '/oauth/token',
        data: data
    })
        .then((response) => {
            console.log(response, "tokendata")
            let token = response.data.access_token
            let userdata = {}
            userdata.token = token
            localStorage.setItem("userdat", JSON.stringify(userdata))
            resolve("success")
        })
        .catch(err => {
            reject("token error")
        })
})
const homeblock4 = dispatch => async (blocks) => {
    let blockdata = blocks
    axios.get(base_url + '/jsonapi/block_content/gallery_walls?include=field_images')
        .then(async (res) => {
            blockdata = res.data
            dispatch({ type: 'homeblock4', payload: blockdata });

        })
}
const shopartBanner = dispatch => async () => {
    axios.get(base_url + '/jsonapi/block_content/shop_art_banner?include=field_shop_art_image')
        .then((res) => {
            dispatch({ type: 'homeblock1', payload: res.data });
        })
}
const viewStore = dispatch => async () => {
    axios.get(base_url + '/jsonapi/block_content/store_carousel?include=field_carousel_image')
        .then((res) => {
            dispatch({ type: 'homeblock2', payload: res.data });
        })
}
const artLove = dispatch => async () => {
    axios.get(base_url + '/jsonapi/taxonomy_term/collection?include=field_collection_image&filter[color][condition][path]=field_checked&filter[color][condition][value]=yes&sort=field_sort')
        .then((res) => {
            dispatch({ type: 'homeblock3', payload: res.data });
        })
}
const framedream = dispatch => async (blocks) => {
    let blockdata = blocks
    axios.get(base_url + '/jsonapi/block_content/home_page_block?include=field_icon')
        .then(async (res) => {
            blockdata = res.data
            dispatch({ type: 'framedream', payload: blockdata });
        })
}
const testimonial = dispatch => async (offset, blocks) => {
    console.log('testoffset', offset)
    let blockdata = blocks
    axios.get(base_url + `/jsonapi/node/testimonial?page[limit]=1&page[offset]=${offset}`)
        .then(async (res) => {
            blockdata = res.data
            dispatch({ type: 'testimonial', payload: blockdata });
        })
}
const testimonials = dispatch => async (blocks) => {
    let blockdata = 0
    axios.get(base_url + `/jsonapi/node/testimonial`)
        .then(async (res) => {
            blockdata = res.data.data.length
            console.log('length', blockdata)
            let kit = res.data.data
            dispatch({ type: 'testlen', payload: blockdata });
            dispatch({ type: 'slick', payload: res.data.data });
        })
}
const order_history = dispatch => async (blocks) => {
    console.log('getid', blocks)
    let blockdata
    let userdata = JSON.parse(localStorage.getItem("userdat"))
    axios.get(base_url + `/jsonapi/commerce_order/default/?include=uid&filter[uid][condition][path]=uid.id&filter[uid][condition][value]=${blocks}&filter[state][condition][path]=state&filter[state][condition][operator]=<>&filter[state][condition][value]=Processing&sort=-placed`, {
        headers: {
            'Accept': `application/vnd.api+json`,
            "Content-type": "application/vnd.api+json",
            "Authorization": `Bearer ${userdata.token}`
        }
    })
        .then(async (res) => {
            blockdata = res.data
            dispatch({ type: 'order_history', payload: blockdata });
        })
}
const single_order = dispatch => async (blocks) => {
    const history = createBrowserHistory()
    let blockdata
    console.log('userorderid', blocks)
    let userdata = JSON.parse(localStorage.getItem("userdat"))
    axios.get(base_url + `/jsonapi/commerce_order/default?filter[order_number]=${blocks}&include=uid,billing_profile,order_items&filter[uid][condition][path]=uid.id&filter[uid][condition][value]=${userdata.details.id}`, {
        headers: {
            'Accept': `application/vnd.api+json`,
            "Content-type": "application/vnd.api+json",
            "Authorization": `Bearer ${userdata.token}`
        }
    })
        .then(async (res) => {
            if (res.data.data.length) {
                blockdata = res.data.data.length ? { data: res.data.data[0], included: res.data.included } : null
                dispatch({ type: 'single_order', payload: blockdata });
            }
            else
                history.push("/404p")
        })
}
const login_signup = dispatch => async (login, email, password) => new Promise((resolve, reject) => {
    let body = {
        "name": {
            "value": email
        },
        "mail": {
            "value": email
        },
        "pass": {
            "value": password
        }
    }
    console.log(body)
    if (!login) {
        axios({
            method: 'post',
            url: base_url + '/jsonapi/user/user/b783cc78-91b9-4d30-8eeb-7cb5708b0068',
            data: body
        })
            .then(({ data_resp }) => {
                getToken(email, password)
                    .then(res => {
                        resolve("success")
                        dispatch({ type: 'set_login_status', payload: true });
                    })
            })
            .catch(err => {
                console.log(err.code)
                reject("user error")
            })
    }
    else {
        getToken(email, password)
            .then(res => {
                resolve("success")
                dispatch({ type: 'set_login_status', payload: true });
            })
    }
});
const login = dispatch => async (name, password) => {
    return new Promise((resolve, reject) => {
        let details = {
            grant_type: "password",
            client_id: "71318786-f26f-471d-9e62-037bd60d243c",
            client_secret: "d8usersecret",
            username: name,
            password: password
        }

        let formBody = [];
        for (let property in details) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
    })
};
const getWishlist = dispatch => () => {
    let userdata = localStorage.getItem("userdat") ? JSON.parse(localStorage.getItem("userdat")) : null
    axios.get(`${base_url}/jsonapi/commerce_wishlist/default?include=wishlist_items.purchasable_entity.product_id.field_image.thumbnail,wishlist_items.purchasable_entity.product_id.field_gallery_image,wishlist_items.purchasable_entity.product_id.field_gifting_image,wishlist_items.purchasable_entity.field_image.thumbnail`, {
        headers: {
            "Authorization": `Bearer ${userdata.token}`
        }
    })
        .then(resp => {
            if (resp.data.data.length) {
                let wishlist = resp.data.data[0]
                let items = { data: wishlist?.relationships.wishlist_items?.data, included: resp.data.included, wishlist_variation: resp.data.included?.filter(x => x.type === "commerce_wishlist_item--commerce_product_variation") }
                console.log("wishlist items", items)
                dispatch(({ type: "wishlist", payload: items }))
            }
        })
        .catch(err => {
            console.log("error on get wishlist", err)
        })
}

export const { Provider, Context } = createDataContext(
    backendReducer,
    { login, login_signup, homeblock4, shopartBanner, viewStore, artLove, framedream, testimonial, order_history, single_order, testimonials, getWishlist },
    {
        articles: null, offset: false
    }
);