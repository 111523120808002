import React, { useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/header/header";
import "./Partnerapply.css";
import { useHistory } from "react-router"
import axios from "axios";
import { useAlert } from "react-alert";
import { base_url } from "../../helpers/baseURL";
import Loader from "../../components/checkout/loader";

const Partnerapply = () => {
    const history = useHistory()
    const alert = useAlert();
    const [loader, setloader] = useState(false)


    const formsubmit = (e) => {
        setloader(true)
        const fname = document.getElementById("fname").value;
        const lname = document.getElementById("lname").value;
        const company = document.getElementById("company").value;
        const website = document.getElementById("website").value;
        const email = document.getElementById("email").value;
        const gst = document.getElementById("gst").value;
        const phone = document.getElementById("phone").value;
        const address = document.getElementById("address").value;
        const city = document.getElementById("city").value;
        const state = document.getElementById("state").value;
        const pin = document.getElementById("pin").value;
        console.log(fname)
        console.log(lname)
        console.log(company)
        console.log(website)
        console.log(gst)
        console.log(phone)
        console.log(address)
        console.log(city)
        console.log(state)
        console.log(pin)

        var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
        var mobileno = /^((\\+91-?)|0)?[0-9]{10}$/;

        var webvalid = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
        var gstvalid = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;


        if (fname && company && website && email && address && city && state && pin && phone) {

            if ((!webvalid.test(website))) {
                // console.log(emailvalid.test(email))
                console.log(email)
                setloader(false)
                alert.show("Enter Valid website address")
            }
            else if ((!phone.match(phoneno)) || (!phone.match(mobileno))) {
                setloader(false)
                alert.show("Enter Valid Phone Number")

            }
            else {
                if (gst) {
                    if ((!gstvalid.test(gst))) {
                        setloader(false)
                        alert.show("Enter Valid GST Number")
                    }
                    else {
                        var axios = require('axios');
                        var data = JSON.stringify({
                            "contact_form": [
                                {
                                    "target_id": "partner_program"
                                }
                            ],
                            "name": [
                                {
                                    "value": fname
                                }
                            ],
                            "field_second_name": [
                                {
                                    "value": lname
                                }
                            ],
                            "mail": [
                                {
                                    "value": email
                                }
                            ],
                            "field_company_name": [
                                {
                                    "value": company
                                }
                            ],
                            "field_city": [
                                {
                                    "value": city
                                }
                            ],
                            "message": [
                                {
                                    "value": "message"
                                }
                            ],
                            "subject": [
                                {
                                    "value": "partner program"
                                }
                            ],
                            "field_company_website": [
                                {
                                    "value": website
                                }
                            ],
                            "field_gst_number": [
                                {
                                    "value": gst
                                }
                            ],
                            "field_phone_number": [
                                {
                                    "value": phone
                                }
                            ],
                            "field_pincode": [
                                {
                                    "value": pin
                                }
                            ],
                            "field_state": [
                                {
                                    "value": state
                                }
                            ],
                            "field_address": [
                                {
                                    "value": address
                                }
                            ]
                        });
                        var config = {
                            method: 'post',
                            url: `${base_url}/contact_message?_format=json`,
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            data: data
                        };

                        axios(config)
                            .then(function (response) {
                                console.log(JSON.stringify(response.data));
                                // console.log("success")
                                setloader(false)
                                alert.show("Form submitted")
                                document.getElementById("partnerForm").reset();
                                history.push("/")
                            })
                            .catch(function (error) {
                                console.log(error);
                                setloader(false)
                                alert.show("Please try later!")
                            });
                    }

                }
                else {
                    var axios = require('axios');
                    var data = JSON.stringify({
                        "contact_form": [
                            {
                                "target_id": "partner_program"
                            }
                        ],
                        "name": [
                            {
                                "value": fname
                            }
                        ],
                        "field_second_name": [
                            {
                                "value": lname
                            }
                        ],
                        "mail": [
                            {
                                "value": email
                            }
                        ],
                        "field_company_name": [
                            {
                                "value": company
                            }
                        ],
                        "field_city": [
                            {
                                "value": city
                            }
                        ],
                        "message": [
                            {
                                "value": "message"
                            }
                        ],
                        "subject": [
                            {
                                "value": "partner program"
                            }
                        ],
                        "field_company_website": [
                            {
                                "value": website
                            }
                        ],
                        "field_gst_number": [
                            {
                                "value": gst
                            }
                        ],
                        "field_phone_number": [
                            {
                                "value": phone
                            }
                        ],
                        "field_pincode": [
                            {
                                "value": pin
                            }
                        ],
                        "field_state": [
                            {
                                "value": state
                            }
                        ],
                        "field_address": [
                            {
                                "value": address
                            }
                        ]
                    });
                    var config = {
                        method: 'post',
                        url: `${base_url}/contact_message?_format=json`,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };

                    axios(config)
                        .then(function (response) {
                            console.log(JSON.stringify(response.data));
                            // console.log("success")
                            setloader(false)
                            alert.show("Form submitted")
                            document.getElementById("partnerForm").reset();
                            history.push("/")
                        })
                        .catch(function (error) {
                            console.log(error);
                            setloader(false)
                            alert.show("Please try later!")
                        });
                }
            }
        }
        else {
            setloader(false)
            alert.show("Enter All Details")
        }


    }
    return (
        <div className="partner-container">
            <Header />
            {loader ? <Loader /> : ""}
            <div className="header-container">
                <div className="selectBack pt-3 brandon d-flex align-self-left" onClick={() => history.goBack()}>
                    <img src="/assets/arrow-left.png" className="selectBackIcon" alt="thumb"></img>
                    <p className='ml-2'>Back</p>
                </div>
            </div>
            <div className="container partnerapply-container">
                <div className="partner-title art-love text-center">
                    Apply Now
                </div>
                <div className="partner-subtitle partner-subtitle1 text-center brandon">
                    Please fill out the form below to join our partner program
                </div>
                <form id="partnerForm" className="d-flex flex-column partner-form mx-auto" onSubmit={(e) => { e.preventDefault(); formsubmit(e) }}>
                    <label className="partner-inputlabel brandon">First Name</label>
                    <input name="First Name" id="fname" className="partner-input" placeholder="First Name" />
                    <label className="partner-inputlabel brandon">Last Name</label>
                    <input name="Last Name" id="lname" className="partner-input" placeholder="Last Name" />
                    <label className="partner-inputlabel brandon">Company</label>
                    <input name="Company" id="company" className="partner-input" placeholder="Company" />
                    <label className="partner-inputlabel brandon">Company Website</label>
                    <input name="Company Website" id="website" className="partner-input" placeholder="Company Website" />
                    <label className="partner-inputlabel brandon">Company Email</label>
                    <input name="Company Email" id="email" className="partner-input" placeholder="Company Email" type={"email"} />
                    <label className="partner-inputlabel brandon">GST Number</label>
                    <input name="GST Number" id="gst" className="partner-input" placeholder="GST Number" />
                    <label className="partner-inputlabel brandon">Phone Number</label>
                    <input name="Phone Number" id="phone" className="partner-input" placeholder="Phone Number" />
                    <label className="partner-inputlabel brandon">Address</label>
                    <input name="Address" id="address" className="partner-input" placeholder="Address" />
                    <label className="partner-inputlabel brandon">City</label>
                    <input name="City" id="city" className="partner-input" placeholder="City" />
                    <label className="partner-inputlabel brandon">State</label>
                    <input name="State" id="state" className="partner-input" placeholder="State" />
                    <label className="partner-inputlabel brandon">Pin Code</label>
                    <input name="Pin Code" id="pin" className="partner-input" placeholder="Pin Code" />

                    <input type="submit" className="partner-submit text-uppercase" />
                </form>
            </div>
            <Footer />
        </div>
    )
}

export default Partnerapply;
