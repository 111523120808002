import createDataContext from './createDataContext';
import axios from 'axios'
import { base_url } from '../helpers/baseURL';
import { createBrowserHistory, createHashHistory } from 'history';

const backendReducer = (state = {}, action) => {
    switch (action.type) {
        case 'objects':
            return {
                ...state, objects: action.payload
            };
        case 'mixed_media':
            return {
                ...state, MixedMedia: action.payload
            };
        case 'all_return':
            return {
                ...state, Return: action.payload
            };
        case 'all_care':
            return {
                ...state, Care: action.payload
            };
        case 'suggestion':
            return {
                ...state, suggestions: action.payload
            };
        default:
            return state;
    }
};
const clearObjects = dispatch => () => {
    dispatch({ type: "objects", payload: null })
}
const getObjects = dispatch => (offset, limit) => {
    axios.get(`${base_url}/jsonapi/commerce_product/object?include=field_object_image.thumbnail,field_obejct_material,default_variation&sort=-created&page[offset]=${offset}&page[limit]=${limit}`)
        .then(res => {
            console.log("objects", res.data.data)
            dispatch({ type: 'objects', payload: { data: res.data.data, included: res.data.included, count: Math.ceil(res.data.meta?.count / limit) } });
        })
        .catch(err => {

        })
}
const getObjectDetails = dispatch => (path) => new Promise((resolve, reject) => {
    let paths = `/object/${path}`
    axios.get(`${base_url}/jsonapi/commerce_product/object?include=field_obejct_material,field_object_color,default_variation,variations.field_color.field_colour,variations.field_image.thumbnail&filter[field_path_alias]=${paths}`)
        .then(res => {
            if (res.data.data.length) {
                let default_variation = res.data.data[0].relationships.default_variation.data?.id ? res.data.included.find(x => x.id === res.data.data[0].relationships.default_variation.data?.id) : null
                let data = { data: res.data.data[0], included: res.data.included, default: default_variation }
                resolve(data)
            }
            else
                reject("not found")
        })
        .catch(err => {
            reject(err)
        })
})

const getMixedMedia = dispatch => (offset, limit) => {
    axios.get(`${base_url}/jsonapi/commerce_product/mixed_media?include=default_variation.field_image.thumbnail&sort=-created&page[offset]=${offset}&page[limit]=${limit}`)
        .then(res => {
            console.log("mixed media", res.data.data)
            dispatch({ type: 'mixed_media', payload: { data: res.data.data, included: res.data.included, count: Math.ceil(res.data.meta?.count / limit) } });
        })
        .catch(err => {

        })
}
const clearMixedMedia = dispatch => () => {
    dispatch({ type: "mixed_media", payload: null })
}
const getMixedMediaDetails = dispatch => (path) => new Promise((resolve, reject) => {
    let paths = `/mixed-media/${path}`
    axios.get(`${base_url}/jsonapi/commerce_product/mixed_media?include=variations.field_image.thumbnail,variations.field_color.field_colour&filter[field_path_alias]=${paths}`)
        .then(res => {
            console.log("mixed details", res.data.data)
            if (res.data.data.length) {
                let default_variation = res.data.data[0].relationships.default_variation.data?.id ? res.data.included.find(x => x.id === res.data.data[0].relationships.default_variation.data?.id) : null
                let data = { data: res.data.data[0], included: res.data.included, default: default_variation }
                resolve(data)
            }
            else
                reject("not found")
        })
        .catch(err => {
            reject(err)
        })
})

const getPolicy = dispatch => () => new Promise((resolve, reject) => {

    axios.get(`${base_url}/jsonapi/node/page?filter[drupal_internal__nid]=361`)
        .then(res => {
            if (res.data.data.length) {
                let data = { data: res.data.data[0] }
                console.log("found artv return", data)
                resolve(data)
            }
        })
        .catch(err => {
            reject("error on Return policy1", err)
        })
})
const getArtCare = dispatch => () => new Promise((resolve, reject) => {
    const prom1 = new Promise((res1, rej1) => {
        axios.get(`${base_url}/jsonapi/node/page?filter[drupal_internal__nid]=362`)
            .then(res => {
                if (res.data.data.length) {
                    let data = res.data.data[0]
                    console.log("found artv return", data)
                    res1({ type: "art_Paper", data: data })
                }
            })
            .catch(err => {
                rej1("error on Return policy5", err)
            })
    })
    const prom2 = new Promise((res1, rej1) => {
        axios.get(`${base_url}/jsonapi/node/page?filter[drupal_internal__nid]=365`)
            .then(res => {
                if (res.data.data.length) {
                    let data = res.data.data[0]
                    console.log("found artv return", data)
                    res1({ type: "art_Canvas", data: data })
                }
            })
            .catch(err => {
                rej1("error on Return policy5", err)
            })
    })
    const prom3 = new Promise((res1, rej1) => {
        axios.get(`${base_url}/jsonapi/node/page?filter[drupal_internal__nid]=364`)
            .then(res => {
                if (res.data.data.length) {
                    let data = res.data.data[0]
                    console.log("found artv return", data)
                    res1({ type: "custom_Paper", data: data })
                }
            })
            .catch(err => {
                rej1("error on Return policy5", err)
            })
    })
    const prom4 = new Promise((res1, rej1) => {
        axios.get(`${base_url}/jsonapi/node/page?filter[drupal_internal__nid]=363`)
            .then(res => {
                if (res.data.data.length) {
                    let data = res.data.data[0]
                    console.log("found artv return", data)
                    res1({ type: "custom_Canvas", data: data })
                }
            })
            .catch(err => {
                rej1("error on Return policy5", err)
            })
    })
    Promise.all([prom1, prom2, prom3, prom4])
        .then(res => {
            console.log("response of final care is", res)
            resolve(res)
        })
        .catch(err => {
            reject("Return with error", err)
            console.log("Care of art with error", err)
        })
})
const getCare = dispatch => () => new Promise((resolve, reject) => {
    const prom1 = new Promise((res1, rej1) => {
        axios.get(`${base_url}/jsonapi/node/page?filter[drupal_internal__nid]=365`)
            .then(res => {
                if (res.data.data.length) {
                    let data = res.data.data[0]
                    console.log("found artv return", data)
                    res1({ type: "art_care", data: data })
                }
            })
            .catch(err => {
                rej1("error on Return policy5", err)
            })
    })
    const prom2 = new Promise((res1, rej1) => {
        axios.get(`${base_url}/jsonapi/node/page?filter[drupal_internal__nid]=366`)
            .then(res => {
                if (res.data.data.length) {
                    let data = res.data.data[0]
                    console.log("found artv return", data)
                    res1({ type: "gallery_care", data: data })
                }
            })
            .catch(err => {
                rej1("error on Return policy5", err)
            })
    })
    const prom3 = new Promise((res1, rej1) => {
        axios.get(`${base_url}/jsonapi/node/page?filter[drupal_internal__nid]=367`)
            .then(res => {
                if (res.data.data.length) {
                    let data = res.data.data[0]
                    console.log("found artv return", data)
                    res1({ type: "mixed_care", data: data })
                }
            })
            .catch(err => {
                rej1("error on Return policy5", err)
            })
    })
    const prom4 = new Promise((res1, rej1) => {
        axios.get(`${base_url}/jsonapi/node/page?filter[drupal_internal__nid]=368`)
            .then(res => {
                if (res.data.data.length) {
                    let data = res.data.data[0]
                    console.log("found artv return", data)
                    res1({ type: "objects_care", data: data })
                }
            })
            .catch(err => {
                rej1("error on Return policy5", err)
            })
    })
    const prom5 = new Promise((res1, rej1) => {
        axios.get(`${base_url}/jsonapi/node/page?filter[drupal_internal__nid]=369`)
            .then(res => {
                if (res.data.data.length) {
                    let data = res.data.data[0]
                    console.log("found artv return", data)
                    res1({ type: "gifts_care", data: data })
                }
            })
            .catch(err => {
                rej1("error on Return policy5", err)
            })
    })
    Promise.all([prom1, prom2, prom3, prom4, prom5])
        .then(res => {
            resolve(res)
            dispatch({ type: "all_care", data: res })
            console.log("response of final return", res)
        })
        .catch(err => {
            reject("Return with error", err)
            console.log("Return with error", err)
        })
})
const setPath = (path) => {
    switch (true) {
        case path.startsWith("/art"):
            return "/art";
        case path.startsWith("/gallery-walls"):
            return "/gallery-walls";
        case path.startsWith("/decor-objects"):
            return "/object";
        case path.startsWith("/mixed-media"):
            return "/mixed-media";
        case path.startsWith("/gifts"):
            return "/gifts";
        default:
            return null;
    }
}
const searchProducts = dispatch => (keyword, path) => {
    console.log("searching", keyword, " from", path)
    let type = setPath(path)
    console.log("search-type", type)
    const specialCharsPattern = /^[^a-zA-Z0-9]*$/;
    if (keyword == "")
        dispatch({ type: 'suggestion', payload: [] });
    else if (specialCharsPattern.test(keyword))
        dispatch({ type: 'suggestion', payload: "no data" });
    else
        axios.get(`${base_url}/search_api_autocomplete/search_api?display=page_1&filter=text&q=${encodeURIComponent(keyword)}`)
            .then(res => {
                let data = [...res.data, { value: keyword }]
                console.log("final keywords", data)
                let promises = data?.map((prom, ind) => {
                    return new Promise((resolve, reject) => {
                        axios.get(`${base_url}/api/search?title=${encodeURIComponent(prom.value)}`)
                            .then(resp => {
                                console.log("search result is", resp.data)
                                resolve(resp.data)
                            })
                            .catch(err => {
                                reject(err)
                                console.error(`error on search ${err}`)
                            })
                    })
                })
                Promise.all(promises)
                    .then(final => {
                        axios.get(`${base_url}/api/search/match_title?title=${encodeURIComponent(keyword)}`)
                            .then(resp => {
                                console.log("final before final", resp.data)
                                let all_data = final.flat()
                                if (resp.data.length)
                                    all_data = resp.data.concat(all_data)
                                let uniqueUUIDs = new Set();

                                // Filter the array to remove duplicates based on UUID
                                let uniqueData = all_data.filter(item => {
                                    if (!uniqueUUIDs.has(item.uuid)) {
                                        uniqueUUIDs.add(item.uuid);
                                        return true;
                                    }
                                    return false;
                                });
                                console.log("final values is", all_data)
                                if (type) {
                                    // if (uniqueData?.filter(x => x.url?.startsWith(type) && x.title).length)
                                    //     dispatch({ type: 'suggestion', payload: uniqueData?.filter(x => x.url?.startsWith(type) && x.title) });
                                    if (uniqueData?.filter(x => x.title).length)
                                        dispatch({ type: 'suggestion', payload: uniqueData?.filter(x => x.title) });
                                    else
                                        dispatch({ type: 'suggestion', payload: "no data" });
                                }
                                else {
                                    if (uniqueData?.filter(x => x.title).length)
                                        dispatch({ type: 'suggestion', payload: uniqueData?.filter(x => x.title) });
                                    else
                                        dispatch({ type: 'suggestion', payload: "no data" });
                                }
                            })
                            .catch(err => {
                                console.error(`error on title search ${err}`)
                            })
                    })
                    .catch(err => {
                        console.error(`error on search ${err}`)
                    })
            })
            .catch(err => {
                console.error(`error on product ${err}`)
            })

}

const addToWish = dispatch => (var_id, type) => new Promise((resolve, reject) => {
    console.log("variation id to add", var_id)
    let userdata = localStorage.getItem("userdat") ? JSON.parse(localStorage.getItem("userdat")) : null
    if (userdata)
        axios.get(`${base_url}/jsonapi/commerce_wishlist/default`, {
            headers: {
                "Authorization": `Bearer ${userdata.token}`
            }
        })
            .then(resp => {
                if (resp.data.data.length) {
                    console.log("response of wishlist", resp.data)
                    let id = resp.data.data[0].id,
                        body = {
                            "data": {
                                "type": "commerce_wishlist_item--commerce_product_variation",
                                "attributes": {
                                    "commerce_wishlist_item_type": "commerce_product_variation",
                                    "quantity": "1.00"
                                },
                                "relationships": {
                                    "wishlist_id": {
                                        "data": {
                                            "type": "commerce_wishlist--default",
                                            "id": id
                                        }
                                    },
                                    "purchasable_entity": {
                                        "data": {
                                            "type": `commerce_product_variation--${type ? type : "default"}`,
                                            "id": var_id
                                        }
                                    }
                                }
                            }
                        }

                    axios({
                        method: "post",
                        url: `${base_url}/jsonapi/commerce_wishlist_item/commerce_product_variation`,
                        data: body,
                        headers: {
                            "Authorization": `Bearer ${userdata.token}`,
                            "Content-Type": 'application/vnd.api+json'
                        }
                    })
                        .then(respo => {
                            resolve("item added")
                        })
                        .catch(err => {
                            reject("wishlist item create error")
                            console.error("error on wishlist item creation")
                        })
                }
                else {
                    console.log("token", userdata.token)
                    axios({
                        method: "post",
                        url: `${base_url}/jsonapi/commerce_wishlist/default`,
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                            "Authorization": `Bearer ${userdata.token}`
                        },
                        data: {
                            "data": {
                                "type": "commerce_wishlist--default",
                                "attributes": {
                                    "name": "Wishlist",
                                    "is_default": true
                                }
                            }
                        }
                    })
                        .then(res => {
                            console.log("response on create", res)
                            let id = res.data.data.id,
                                body = {
                                    "data": {
                                        "type": "commerce_wishlist_item--commerce_product_variation",
                                        "attributes": {
                                            "commerce_wishlist_item_type": "commerce_product_variation",
                                            "quantity": "1.00"
                                        },
                                        "relationships": {
                                            "wishlist_id": {
                                                "data": {
                                                    "type": "commerce_wishlist--default",
                                                    "id": id
                                                }
                                            },
                                            "purchasable_entity": {
                                                "data": {
                                                    "type": "commerce_product_variation--default",
                                                    "id": var_id
                                                }
                                            }
                                        }
                                    }
                                }

                            axios({
                                method: "post",
                                url: `${base_url}/jsonapi/commerce_wishlist_item/commerce_product_variation`,
                                data: body,
                                headers: {
                                    "Content-Type": "application/vnd.api+json",
                                    "Authorization": `Bearer ${userdata.token}`
                                },
                            })
                                .then(respo => {
                                    resolve("item added")
                                })
                                .catch(err => {
                                    reject("wishlist item create error")
                                    console.error("error on wishlist item creation")
                                })
                        })
                        .catch(err => {
                            console.error("error on wishlist create", err)
                            reject("whishlist create error")
                        })
                }
            })
            .catch(err => {
                console.error("error on wishlist get", err)
                reject("wishlist error")
            })
    else {
        reject("logged_out")
    }
})

const deleteFromWish = dispatch => (id) => new Promise((resolve, reject) => {
    let userdata = localStorage.getItem("userdat") ? JSON.parse(localStorage.getItem("userdat")) : ""
    axios.delete(`${base_url}/jsonapi/commerce_wishlist_item/commerce_product_variation/${id}`,
        {
            headers: {
                "Authorization": `Bearer ${userdata?.token}`
            }
        })
        .then(resp => {
            console.log("item deleted from wishlist", resp.data.data)
            resolve("item deleted")
        })
        .catch(err => {
            console.log("error on wishlist item delete", err)
            reject("error on delete")
        })
})
const checkWish = dispatch => (id, path) => new Promise((resolve, reject) => {
    console.log(id, "id passed")
    let userdata = localStorage.getItem("userdat") ? JSON.parse(localStorage.getItem("userdat")) : null
    if (userdata) {
        if (path === "art")
            axios.get(base_url + `/jsonapi/commerce_product_variation/default?include=product_id,attribute_media&filter[productFilter][condition][path]=product_id.id&filter[productFilter][condition][value]=${id}`)
                .then(
                    res => {
                        console.log(res.data.data[0], "uu artss var")
                        axios.get(`${base_url}/jsonapi/commerce_wishlist/default?include=wishlist_items.purchasable_entity.product_id`, {
                            headers: {
                                "Authorization": `Bearer ${userdata.token}`
                            }
                        })
                            .then(resp => {
                                if (resp.data.data.length) {
                                    resp.data.included.filter(x => x.type === "commerce_wishlist_item--commerce_product_variation")
                                    let find = resp.data.included.filter(x => x.type === "commerce_wishlist_item--commerce_product_variation").find(x => x.relationships.purchasable_entity?.data?.id === res.data.data[0].id)
                                    console.log("found the match", find)
                                    resolve(find)
                                }
                            })
                            .catch(err => {
                                console.error("error on wishlist found", err)
                                reject("error on wishlist found")
                            })
                    })
                .catch(err => {
                    reject("variation issue")
                    console.log("variation issue", err)
                })
        else
            axios.get(`${base_url}/jsonapi/commerce_wishlist/default?include=wishlist_items.purchasable_entity.product_id`, {
                headers: {
                    "Authorization": `Bearer ${userdata.token}`
                }
            })
                .then(resp => {
                    if (resp.data.data.length) {
                        resp.data.included.filter(x => x.type === "commerce_wishlist_item--commerce_product_variation")
                        let find = resp.data.included.filter(x => x.type === "commerce_wishlist_item--commerce_product_variation").find(x => x.relationships.purchasable_entity?.data?.id === id)
                        console.log("found the match", find)
                        resolve(find)
                    }
                })
                .catch(err => {
                    console.error("error on wishlist found", err)
                    reject("error on wishlist found")
                })
    }
})

export const { Provider, Context } = createDataContext(
    backendReducer,
    { getObjects, getObjectDetails, getMixedMedia, getMixedMediaDetails, getPolicy, getCare, getArtCare, clearMixedMedia, clearObjects, addToWish, deleteFromWish, checkWish, searchProducts },
    {
        objects: null
    }
);